<template>
    <b-modal :active.sync="isModalActive" :width="600" scroll="keep" :can-cancel="canCancel" @close="closeModal">
        <div class="border border-blue-light rounded shadow bg-white p-4">
            <form @submit.prevent="saveInvoiceIndirectCost" class="w-full">
                <header class="font-thin uppercase text-blue-light modal-card-head_ mb-4">
                    {{ $t('add_invoice') }}
                </header>
                <div class="modal-card-body_ relative">
                    <div class="flex flex-wrap">
                        <div class="w-1/2 px-2">
                            <b-field :label="$t('quantity')+ $t('(sobre coste previsto)')">
                                <b-input type="number" step=".01" v-model="invoiceCostIndirect.assignment"
                                    title="quantity" required name="quantity" :placeholder="$t('quantity')" />
                            </b-field>
                        </div>
                        <div class="w-1/2 px-2">
                            <b-field :label="'Coste real a origen'">
                                <b-input v-model="invoiceCostIndirect.price" title="price" required name="price"
                                    :placeholder="$t('price')" type="number" step=".01" />
                            </b-field>
                        </div>
                    </div>
                    <div class="flex flex-wrap mx-4 mb-3 justify-end">
                        <v-button type="submit" class="px-4 mt-3">{{ $t('save') }}</v-button>
                    </div>
                </div>
            </form>
        </div>
    </b-modal>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        projectId: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            isModalActive: false,
            canCancel: ['escape', 'x', 'outside'],
            invoiceCostIndirect: {
                assignment: 0,
                price: 0,
                indirect_cost_detail_id: 0
            },
            invoiceIndirectCostId: null
        };
    },
    methods: {
        show(indirectCost, invoiceIndirectCost = null) {
            if (invoiceIndirectCost) {
                this.invoiceCostIndirect = {
                    assignment: String(invoiceIndirectCost.assignment) || '0',
                    price: Number(invoiceIndirectCost.price) || 0,
                    indirect_cost_detail_id: indirectCost.id
                };
                this.invoiceIndirectCostId = invoiceIndirectCost.id || null;
            } else {
                this.invoiceCostIndirect = {
                    assignment: '0',
                    price: 0,
                    indirect_cost_detail_id: indirectCost.id
                };
                this.invoiceIndirectCostId = null;
            }
            this.isModalActive = true;
        },
        closeModal() {
            this.isModalActive = false;
        },
        async saveInvoiceIndirectCost() {
            try {
                let response;
                const data = {
                    assignment: this.invoiceCostIndirect.assignment,
                    price: this.invoiceCostIndirect.price,
                    indirect_cost_detail_id: this.invoiceCostIndirect.indirect_cost_detail_id,
                    project_id: this.projectId
                };

                if (this.invoiceIndirectCostId !== null) {
                    // Actualizar (PUT)
                    response = await axios.put(`/api/v2/invoice/indirect-cost/${this.invoiceIndirectCostId}`, data);
                } else {

                    // Crear (POST)
                    response = await axios.post('/api/v2/invoice/indirect-cost', data);

                }

                if (response.data.success) {
                    this.$toast.open({
                        message: this.$t('save_successful'),
                        type: 'is-success',
                        position: 'is-top-right'
                    });
                    this.$emit('reload-list');
                    this.closeModal();
                } else {
                    this.$toast.open({
                        message: this.$t('save_error'),
                        type: 'is-danger',
                        position: 'is-top-right'
                    });
                }
            } catch (error) {
                this.$toast.open({
                    message: 'Error saving indirect cost',
                    type: 'is-danger',
                    position: 'is-top-right'
                });
                console.error('Error saving indirect cost:', error);
            }
        }
    }
}
</script>

<style scoped>
/* Optional: Custom styling to adjust the form layout */
</style>