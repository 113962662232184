<template>
  <div>
    <!-- Filtros -->
     <div class="w-full flex justify-between below-top-bar">
        <div class="flex">
        </div>
    </div>

    <!-- Tabla de productos -->
    <div class="panel">

      <b-table
        :data="products"
        :loading="isLoadingTable"

        paginated
        backend-pagination
        :per-page="perPage"
        :total="total"
        @page-change="onPageChange"

        backend-sorting
        :default-sort="orderField"
        :default-sort-direction="orderDir"
        @sort="onSort"

        class="w-full"
        striped
        bordered
        icon-pack="fas"
      >

        <template>
          <b-table-column field="code" :label="$t('presto_code')" sortable v-slot="props">
            {{ props.row.code }}
          </b-table-column>
          
          <!-- TODO: se comento esto ya que se mostrara directamente codigo que visualmente se vera como presto_code por razones que en el modulo presupuesto de costes se usa code  -->
          <!-- <b-table-column field="presto_code" :label="$t('presto_code')" sortable v-slot="props">
            {{ props.row.presto_code }}
          </b-table-column> -->

          <b-table-column field="name" :label="$t('name')" sortable v-slot="props">
            {{ props.row.name }}
          </b-table-column>

          <!-- <b-table-column field="unit" :label="$t('unit')" sortable v-slot="props">
            {{ props.row.unit ? props.row.unit.name : '' }}
          </b-table-column> -->

          <b-table-column :label="$t('actions')" field="actions" centered width="150" v-slot="props">
            <div class="w-full h-full flex items-center justify-around">
              <b-tooltip :label="$t('edit_project')" position="is-left">
                <div @click="editButtonClicked(props.row)"
                     class="cursor-pointer flex text-blue">
                  <b-icon pack="fas" icon="pencil-alt"/>
                </div>
              </b-tooltip>
              <!-- <b-tooltip :label="$t('archive_project')" position="is-left">
                <div @click="archiveProjectButtonClicked(props.row.id, props.row.name, props.row.closed_at)"
                     :class=archiveClass(props.row.closed_at)>
                  <b-icon pack="fas" icon="folder-open"/>
                </div>
              </b-tooltip> -->
              <b-tooltip :label="$t('remove_project')" position="is-left" type="is-danger">
                <div @click="deleteButtonClicked(props.row.id, props.row.name)"
                     class="cursor-pointer flex text-red">
                  <b-icon pack="fas" icon="trash-alt"/>
                </div>
              </b-tooltip>
            </div>
          </b-table-column>

        </template>

        <template #empty>
          <div class="has-text-centered">{{ $t('no_results') }}</div>
        </template>
      </b-table>

    </div>

  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import axios from 'axios'

  export default {

    components: {

    },

    props: {
      projectId: {type: Number, require: true },
      emitParentList: { type: Boolean, default: false }
    },

    computed: {
      ...mapGetters({
        company: "app/company",
      }),

      filters() {
        let filters = {
          'order_field': this.orderField,
          'order_dir': this.orderDir,
          'perPage': this.perPage,
          'page': this.page,
        }

        return filters
      }
    },

    data: function() {
      return {
        isLoadingTable: true,
        products: [],

        perPage: 25,
        orderField: 'name',
        orderDir: 'asc',
        currentPage: 1,
        page: 1,
        last: 1,
        total: 0,
      }
    },

    created() {
      this.getProducts()
    },

    methods: {

      async getProducts(filters=null) {
        this.isLoadingTable = true
        this.products = []

        if( filters == null )
          filters = this.filters

        const {data} = await axios.get(`/api/v2/project/${this.projectId}/store-products`, {params: filters})

        if (data && data.success) {
          this.products = data.products
          this.last = data.lastPage;
          this.total = data.total;
          this.isLoadingTable = false

          // hacemos que el padre envie un evento con la lista de los primeros 25 productos
          if( this.emitParentList && this.page == 1 )
            this.$parent.$emit('epc-store-products', this.products)
        }
      },

      onSort(field, order) {
        this.companyId = this.company.id
        this.orderField = field
        this.orderDir = order
        this.getProducts()
      },

      onPageChange(page) {
        this.page = page
        this.getProducts()
      },

      reload(arg = {}) {
        let filters = { ...arg, ...this.filters }
        this.getProducts(filters);
      },

      async editButtonClicked(entity) {
        this.$emit('epc-edit', entity)
      },

      deleteButtonClicked(materialId, materialName) {
        this.$dialog.confirm({
          title: this.$t('delete'),
          message: this.$t('delete_confirm_text', ['el material', materialName]),
          confirmText: this.$t('delete') + ' ' + this.$t('material'),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            let url = '/api/v2/store-product/' + materialId + '/delete'
            const {data} = await axios.post(url)
            if (data && data.success) {
              this.$notify.success('success_deleting_generic')
              this.getProducts()
            } else {
              this.$notify.error(data.error)
            }
          }
        })
      },

      archiveClass(value) {
        return value ? "cursor-pointer flex text-yellow-dark" : "cursor-pointer flex text-blue"
      },

      handleSelect(newRow, oldRow) {
        this.$emit('update:template', newRow);
      },

      clearSelected() {
        this.rowSelected = null
      }
    },

  };
</script>
