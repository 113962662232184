<template>
  <div>
    <!-- Tabla de productos -->
    <div class="panel">

      <b-table :data="products" :loading="isLoadingTable" paginated backend-pagination :per-page="perPage"
        :total="total" @page-change="onPageChange" backend-sorting :default-sort="orderField"
        :default-sort-direction="orderDir" @sort="onSort" class="w-full" striped bordered icon-pack="fas" detailed
        detail-key="code" :show-detail-icon="showDetailIcon" scrollable>

        <template>

          <b-table-column field="code" :label="$t('code')" sortable v-slot="props">
            {{ props.row.code }}
          </b-table-column>

          <b-table-column field="name" :label="$t('name')" sortable v-slot="props">
            {{ props.row.product_name }}
          </b-table-column>

          <b-table-column field="main_diameter" :label="$t('main_diameter')" sortable v-slot="props">
            {{ getValueExtrafield(props.row.product.extrafields, 'diametro_principal') }}
          </b-table-column>

          <b-table-column field="type_product" :label="$t('type_product')" sortable v-slot="props">
            {{ getValueExtrafield(props.row.product.extrafields, 'tipo_producto') }}
          </b-table-column>

          <b-table-column field="material" :label="$t('material')" sortable v-slot="props">
            {{ getValueExtrafield(props.row.product.extrafields, 'material') }}
          </b-table-column>

          <b-table-column field="main_diameter" :label="$t('main_diameter')" sortable v-slot="props">
            {{ getValueExtrafield(props.row.product.extrafields, 'diametro_secundario') }}
          </b-table-column>

          <b-table-column field="comment_single" :label="$t('comment_single')" sortable v-slot="props">
            {{ getValueExtrafield(props.row.product.extrafields, 'comentario') }}
          </b-table-column>

          <b-table-column field="purpose" :label="$t('purpose')" sortable v-slot="props">
            {{ getValueExtrafield(props.row.product.extrafields, 'proposito') }}
          </b-table-column>


          <b-table-column field="stock" :label="$t('stock')" sortable v-slot="props">
            {{ props.row.stock }}
          </b-table-column>

          <b-table-column field="stock_min" :label="$t('stock_min')" sortable v-slot="props">
            {{ props.row.stock_min }}
          </b-table-column>

        </template>
        <template #detail="props">
          <div v-if="props.row.purchases.length > 0">
            <b-table :data="props.row.purchases" :loading="isLoadingTable" class="w-full" striped bordered
              icon-pack="fas" scrollable>
              <template>
                <b-table-column field="custom_code" :label="$t('code')" sortable v-slot="props">
                  {{ formatValue(props.row.custom_code) }}
                </b-table-column>

                <b-table-column field="provider" :label="$t('provider')" sortable v-slot="props">
                  {{ formatValue(props.row.inventory.provider.name) }}
                </b-table-column>

                <b-table-column field="quantity" :label="$t('quantity')" sortable v-slot="props">
                  {{ formatValue(props.row.qty) }}
                </b-table-column>

                <b-table-column field="price" :label="$t('price')" sortable v-slot="props">
                  {{ formatValue(props.row.price )}}
                </b-table-column>

                <b-table-column field="warehouse" :label="$t('storehouse')" sortable v-slot="props">
                  {{ props.row.warehouse? props.row.warehouse.name:"-" }}
                </b-table-column>

                <b-table-column field="delivery_note" :label="$t('delivery_note')" sortable v-slot="props">
                  {{ formatValue(props.row.delivery_note )}}
                </b-table-column>
                <b-table-column field="created_at" :label="$t('created_at')" sortable v-slot="props">
                  {{ props.row.created_at ? moment(props.row.created_at).format("DD/MM/YYYY HH:mm:ss") : "-" }}
                </b-table-column>

              </template>
            </b-table>
          </div>
          <div v-else class="text-center py-4">
            {{ $t('no_results') }}
          </div>
        </template>

        <!-- <b-table-column field="provider" :label="$t('provider')" sortable v-slot="props">
          {{ props.row.provider.name }}
        </b-table-column>

        <b-table-column field="main_diameter" :label="$t('main_diameter')" sortable v-slot="props">
          {{ getValueExtrafield(props.row.product.extrafields, 'diametro_principal') }}
        </b-table-column>

        <b-table-column field="type_product" :label="$t('type_product')" sortable v-slot="props">
          {{ getValueExtrafield(props.row.product.extrafields, 'tipo_producto') }}
        </b-table-column>

        <b-table-column field="warehouse_location" :label="$t('warehouse_location')" sortable v-slot="props">
          {{ getValueExtrafield(props.row.product.extrafields, 'ubicacion_almacen') }}
        </b-table-column>

        <b-table-column field="stock" :label="$t('stock')" sortable v-slot="props" class="text-center">
          {{ showQuantity(props.row.stock) }}
        </b-table-column>

        <b-table-column field="stock_min" :label="$t('stock_min')" sortable v-slot="props" class="text-center">
          {{ showQuantity(props.row.stock_min) }}
        </b-table-column> -->

        <!-- <b-table-column :label="$t('actions')" field="actions" centered width="150" v-slot="props">
            <div class="w-full h-full flex items-center justify-around">
              <b-tooltip :label="$t('edit_project')" position="is-left">
                <div @click="editButtonClicked(props.row)"
                     class="cursor-pointer flex text-blue">
                  <b-icon pack="fas" icon="pencil-alt"/>
                </div>
              </b-tooltip>
              <b-tooltip :label="$t('remove_project')" position="is-left" type="is-danger">
                <div @click="deleteButtonClicked(props.row.id, props.row.name)"
                     class="cursor-pointer flex text-red">
                  <b-icon pack="fas" icon="trash-alt"/>
                </div>
              </b-tooltip>
            </div>
          </b-table-column> -->



        <template #empty>
          <div class="has-text-centered">{{ $t('no_results') }}</div>
        </template>
      </b-table>

    </div>

  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import axios from 'axios'
  import moment from 'moment'
  import { parseQuantity } from './helpers.js'

  export default {

    components: {

    },

    props: {
      projectId: {type: Number, require: true },
    },

    computed: {
      ...mapGetters({
        company: "app/company",
      }),

      filters() {
        let filters = {
          'order_field': this.orderField,
          'order_dir': this.orderDir,
          'perPage': this.perPage,
          'page': this.page,
        }

        return filters
      }
    },

    data: function() {
      return {
        isLoadingTable: true,
        products: [],

        perPage: 25,
        orderField: 'name',
        orderDir: 'asc',
        page: 1,
        last: 1,
        total: 0,

        applyFilters: { },
        showDetailIcon: true,
      }
    },

    created() {
      this.getInventary()
    },

    methods: {

      async getInventary(filters = null) {
        this.isLoadingTable = true
        this.products = []

        if( filters != null )
          this.applyFilters = { ...this.filters, ...filters }

        const {data} = await axios.get(`/api/v2/project/${this.projectId}/store-inventories`, {params: this.applyFilters})
        if (data && data.success) {
          this.products = data.products
          this.last = data.lastPage;
          this.total = data.total;
          this.isLoadingTable = false
        }
      },

      onSort(field, order) {
        this.companyId = this.company.id
        this.orderField = field
        this.orderDir = order
        this.getInventary()
      },

      onPageChange(page) {
        this.page = page
        this.getInventary()
      },

      reload(arg = {}) {
        let filters = { ...arg, ...this.filters }
        this.getInventary(filters);
      },

      async editButtonClicked(entity) {
        this.$emit('epc-edit', entity)
      },

      deleteButtonClicked(materialId, materialName) {
        this.$dialog.confirm({
          title: this.$t('delete'),
          message: this.$t('delete_confirm_text', ['el material', materialName]),
          confirmText: this.$t('delete') + ' ' + this.$t('material'),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            let url = '/api/v2/store-inventory/' + materialId + '/delete'
            const {data} = await axios.post(url)
            if (data && data.success) {
              this.$notify.success('success_deleting_generic')
              this.getInventary()
            } else {
              this.$notify.error(data.error)
            }
          }
        })
      },

      archiveClass(value) {
        return value ? "cursor-pointer flex text-yellow-dark" : "cursor-pointer flex text-blue"
      },

      handleSelect(newRow, oldRow) {
        this.$emit('update:template', newRow);
      },

      clearSelected() {
        this.rowSelected = null
      },

      showQuantity(quantity) {
        return parseQuantity(quantity);
      },

      getValueExtrafield(extrafields, fieldCode) {
        let field = extrafields.find(function (el) {
          return el.code == fieldCode;
        });

        let valueField = '';
        if (field) {
          switch (fieldCode) {
            case 'tipo_producto':
            case 'ubicacion_almacen':
            case 'proposito':
              valueField = field.value ? field.value.name : null;
              break;

            case 'diametro_principal':
            case 'diametro_secundario':
            case 'comentario':
              valueField = field.value_free;
              break;

            case 'material':
              valueField = field.value ? field.value.name : null;
              break;
          }
        }
        return valueField;
      },
        formatValue(value) {
        return value ? value : "-";
      }
    },

  };
</script>
