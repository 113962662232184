<template>
    <div class="relative w-full" ref="viewer_page">
        <!-- <div class="panel relative w-4/5" style="height: 100vh;"> -->
        <ViewerHeader @epc-open-panel="_HandleOpenPanel"/>
        <div v-if="!showPanelViews"
          class="p-2 text-sm text-gray-500 bg-white">
          <div class="h-9">
            Trabajando en la vista
            <span class="text-gray-700 lowercase border-b-2 border-dotted font-semibold cursor-pointer"
              @click="() => { showPanelViews = true }">{{ name_vista }}
            </span>

              <div v-if="options.showButtonInfo" class="inline-block float-right">
                <b-tooltip :label="'Información de ' + name_vista"
                  position="is-left">
                  <button class="h-9 w-9 rounded-full pressed"
                  type="button"
                  @click="_HandleClickInfoPictogram"
                  >
                    <b-icon icon="info" pack="fas"></b-icon>
                  </button>
                </b-tooltip>
              </div>

              <div v-if="options.showButtonCompletion && displayPictogramLayer === 'modules'"
                class="inline-block float-right mr-2">
                <b-tooltip label="Visualizar estrucutras completadas"
                  position="is-left">
                  <button class="h-9 w-9 rounded-full"
                  :class="{ 'pressed': showLayerCompleted, 'not-pressed': !showLayerCompleted}"
                  type="button"
                  @click="(e) => showLayerCompleted = !showLayerCompleted"
                  >
                    <b-icon icon="object-group" pack="fas"></b-icon>
                  </button>
                </b-tooltip>
              </div>

              <div v-if="options.showButtonReturn" class="inline-block float-right mr-2">
                <b-tooltip label="Volver"
                  position="is-left">
                  <button class="h-9 w-9 rounded-full pressed"
                  type="button"
                  @click="_HandleReturnClick"
                  >
                    <b-icon icon="rotate-left" pack="fas"></b-icon>
                  </button>
                </b-tooltip>
              </div>
          </div>
        </div>
        <div class="relative w-full flex">
          <ViewerPanelViews v-if="showPanelViews"
              :views="pictogram.layers ? pictogram.layers : []"
              :workLayer="workLayer"
              :displayView="displayPictogramLayer"
              @epc-view-selected="_OnClickDisplayLayerPictogram"
              @epc-close="(e) => { showPanelViews = !e }"/>
          <div class="relative w-full flex overflow-hidden">
            <KonvaViewer ref="viewer" :key="componentViewerKey"
              :pictogram="pictogram"
              :fonts="fonts"
              :workLayer="workLayer"
              :canActionsPictogram="canActionsPictogram"
              :show-layer-completed="showLayerCompleted"
              :type-register="typeRegister"
              @epc-loaded="_OnLoaded"
              @epc-loaded-scene="_OnLoadedScene"
              @epc-mounted="_OnMounted"
              @epc-cleared="_OnCleared"
              @epc-message="_OnMessage"
              @epc-pointerdown="_onPointerDown"
              @epc-changestatuses="_OnShowChangeStatuses"
              @epc-open-split="_OnShowFormSplit"
              @epc-isloading="(e) => { isLoading = e }"
              @epc-reload="() => { $emit('epc-reload') }"
              @epc-saving="(e) => { isLoading = e.loading; customLoaderText = e.text }"
              @epc-screenshot="(e) => { screenshot = e }"
            />
          </div>
          <ViewerPanelScreenshot v-if="showPanelScreenshot"
            ref="refScreenshot"
            :views="pictogram.layers ? pictogram.layers : []"
            :workLayer="displayPictogramLayer"
            :screenshot="screenshot"
            @epc-view-selected="_OnClickDisplayLayerPictogram"
            @epc-close="(e) => { showPanelScreenshot = !e }"/>

          <ViewerPanelComments v-if="false"
            :views="pictogram.layers ? pictogram.layers : []"
            :workLayer="workLayer"
            :screenshot="screenshot"
            @epc-view-selected="_OnClickDisplayLayerPictogram"
            @epc-close="(e) => { showPanelViews = !e }"/>

          <LayersToolStatuses v-show="showLayerToolStatuses"
            :selected-entities="selectedEntities"
            :statuses="statuses"
            :cad_layers_design="pictogram.metadata.cad_layers_design"
            :type-register="typeRegister"
            @epc-konva-changestatus="_HandleChangeStatus"
            @epc-close="(close) => showLayerToolStatuses = !close" />
        </div>
        <div class="epc-toolbar">
          <!-- <div id="settingsTools" class="epc-control-group">
            <div id="toolbar-layersTools" class="epc-button inactive" :class="{ 'active' : showLayerPanel }" @click="(e) => showLayerPanel = !showLayerPanel">
              <b-icon pack="fas" icon="layer-group"></b-icon>
            </div>

            <div v-for="(lyr, index) of pictogram.layers" :key="lyr.id"
              class="epc-button inactive"
              :id="'toolbar-' + lyr.code.toLowerCase() + 'Tools'"
              :class="{ 'active' : displayPictogramLayer === lyr.code }"
              @click="_OnClickDisplayLayerPictogram(lyr.code)">
                <b-icon pack="fas" :icon="_GetIcon(lyr)"></b-icon>
            </div>
          </div> -->

          <div id="settingsTools" class="epc-control-group">
            <div id="toolbar-panTool" class="epc-button inactive"
              :class="{ 'active' : isDraggable }" @click="_OnClickPan()">
              <b-icon pack="fas" icon="hand-paper"></b-icon>
            </div>
            <div id="toolbar-fitTools" class="epc-button inactive" @click="_OnFitContent()">
              <b-icon pack="fas" icon="arrows-to-circle"></b-icon>
            </div>
            <div id="toolbar-zoomInTools" class="epc-button inactive" @click="_OnClickZoom(true)">
              <b-icon pack="fas" icon="search-plus"></b-icon>
            </div>
            <div id="toolbar-zoomOutTools" class="epc-button inactive" @click="_OnClickZoom(false)">
              <b-icon pack="fas" icon="search-minus"></b-icon>
            </div>

            <div id="toolbar-selectingTools" class="epc-button inactive"
              :class="{ 'active': isSelecting }" @click="_OnClickSelecting()">
              <b-icon pack="fas" icon="object-group"></b-icon>
            </div>

            <div id="toolbar-selectingTools" class="epc-button inactive"
              @click="_OnClickUnselect()">
              <b-icon pack="fas" icon="object-ungroup"></b-icon>
            </div>

            <div id="toolbar-fullscreenTools" class="epc-button inactive" @click="_OnClickFullScreen()">
              <b-icon pack="fas" icon="expand"></b-icon>
            </div>
          </div>

          <div id="settingsTools" class="epc-control-group" v-if="_AllowedAction('save')">
            <div id="toolbar-saveTools" class="epc-button inactive" @click="_HandleClickSave()">
              <b-icon pack="fas" icon="save"></b-icon>
            </div>
          </div>
        </div>

        <div class="docking-panel layers-panel" style="display: none;">
          <div class="docking-panel-title docking-panel-delimiter-shadow">Capas</div>
          <div class="docking-panel-close"></div>
          <div class="docking-panel-footer">
            <div class="docking-panel-footer-resizer"></div>
          </div>
        </div>

        <LayersListKonva v-show="showLayerPanel"
          :layers="filterLayers"
          @toggleLayer="_OnToggleLayer"
          @toggleAll="_OnToggleAll"
          @epc-close="(close) => showLayerPanel = !close"/>

        <LayersToolSplit v-show="showLayerToolSplit"
          :selected-entities="selectedEntities"
          :statuses="statuses"
          @epc-konva-split="handleSplitEntities"
          @epc-close="(close) => showLayerToolSplit = !close" />

          <!-- <b-loading :is-full-page="false" v-model="isloading" :can-cancel="true"></b-loading> -->

        <ViewerSpinnerLoader ref="refSpinnerLoader" :is-loading="isLoading" :custom-loader-text="customLoaderText"/>

        <InfoModal ref="refInfoModal" />
    </div>

</template>

<script>
import KonvaViewer from "./KonvaViewer"
import Vue from "vue"
import mainFont from "../viewer/assets/fonts/Roboto-LightItalic.ttf"
import aux1Font from "../viewer/assets/fonts/NotoSansDisplay-SemiCondensedLightItalic.ttf"
import aux2Font from "../viewer/assets/fonts/HanaMinA.ttf"
import aux3Font from "../viewer/assets/fonts/NanumGothic-Regular.ttf"
import LayersListKonva from "./LayersListKonva"
import LayersToolStatuses from "./LayersToolStatuses.vue"
import LayersToolSplit from "./LayersToolSplit.vue"
import ViewerHeader from "./ViewerHeader.vue"
import ViewerPanelViews from "./ViewerPanelViews.vue"
import ViewerPanelComments from "./ViewerPanelComments.vue"
import ViewerSpinnerLoader from "./ViewerSpinnerLoader.vue"
import ViewerPanelScreenshot from "./ViewerPanelScreenshot.vue"
import InfoModal from "./Info-modal.vue"

export default {
    name: "ViewerPageKonva",
    components: {
      KonvaViewer,
      LayersListKonva,
      LayersToolStatuses,
      LayersToolSplit,
      ViewerHeader,
      ViewerPanelViews,
      ViewerPanelComments,
      ViewerSpinnerLoader,
      ViewerPanelScreenshot,
      InfoModal
    },

    props: {
      pictogram: {
        type: Object,
        default() { return {} },
      },
      workLayer: { type: String, default: null },
      activityId: { type: Number, default: null },
      subactivityId: { type: Number, default: null },
      typeRegister: { type: String, default: 'progress' }, // Bandera usado para identificar si se ba a registrar progreso u incidencia
      canActionsPictogram: { type:Object, default() { return {} } } // acciones que se puede realizar sobre el pictograma por defecto ninguna
    },

    data() {
      return {
        layers: null,
        filterLayers: null,
        showLayerPanel: false,
        displayPictogramLayer: '', // capa actual del pictograma que se está visualizando
        displayDefaultPictogramLayer: null, // capa por defecto del pictograma a visualizar
        heightContainerKonva: '75',
        fullscreenEnabled: false, // si api navegador permite full screen
        isDraggable: false,
        isSelecting: false,

        showLayerToolStatuses: false,
        selectedEntities: [], // entidades seleccionadas para cambiar de estado enviado desde konvaviewer.vue
        statuses: [], // estados que se aplican en una capa enviado desde konvaviewer.vue

        showLayerToolSplit: false,

        showPanelViews: false,
        showLayerCompleted: false, // visualiza una capa representando las estructuras que tienen estado completado por defecto false
        showPanelScreenshot: false, // bandera que indica si se muestra o no el panel de screenshots
        screenshot: null,


        isLoading: false, // Indica si esta visible el loader
        customLoaderText: null, // texto mostrado en el loader
        optionsDefatul: {
          showButtonReturn: true,
          showButtonCompletion: true,
          showButtonInfo: true
        },

        componentViewerKey: 'viewer-konva-' + Math.random().toString(36).substring(7)
      }
    },

    computed: {
      options() {
        return Object.assign({}, this.optionsDefatul, this.canActionsPictogram)
      },

      name_vista() {
        if (this.pictogram.layers)
          return this.pictogram.layers.find((lyr) => { return lyr.code === this.displayPictogramLayer }).name
        return null
      },
    },

    watch: {
      displayPictogramLayer(newVal, oldVal) {
        this.showLayerCompleted = newVal == 'modules'
      }
    },

    mounted() {
    },

    methods: {
      ShowLoader(data) {
        this.isLoading = data.loading;
        this.customLoaderText = data.text
      },

      ShowPanelScreenshot(show) {
        this.showPanelScreenshot = show
      },

      // Method que obtiene las capturas de las diferentes capas
      async GetScreenshotsLayers() {
        let screenshots = []
        for (const lyr of this.pictogram.layers) {
          this._OnClickDisplayLayerPictogram(lyr.code)
          // await this.sleep(3000) // enves de realizar el fit pausamos 1 segundo

          await this.$refs.viewer.FitToScreen()
          .then(result => {
            let img = this.$refs.refScreenshot.getScreenShot()
            screenshots.push({
              name: lyr.code + '.png',
              src: this.$refs.refScreenshot.Base64ToBlob(img.replace('data:image/png;base64,', ''), 'image/png')
            })
          })
          await this.sleep(500) // Por si las moscas esperamos 1/2 seg entre captura
        }

        return screenshots
      },

      _OnLoaded() {
          // const layers = this.$refs.viewer.GetLayers()
          // layers.forEach(lyr => Vue.set(lyr, "isVisible", true))
          // this.layers = layers
      },

      _OnLoadedScene() {
          const layers = this.$refs.viewer.GetLayers()
          layers.forEach(lyr => Vue.set(lyr, "isVisible", true))
          this.layers = layers

          this.$refs.viewer.ShowPicComplete(this.showLayerCompleted)
      },

      _OnMounted() {
        // this._FilterLayer()
        this._OnClickDisplayLayerPictogram(this.displayPictogramLayer)
      },

        _FilterLayer() {
          // Primero filtramos a queyos que en el name layer tiene el prifijo el codigo del layer a visualizar
          // luego verificamos si esto devuelve un resultado, caso contrario se filtrar a aquellos que no contengan
          // EPC en el nombre

          let filterType = this.displayPictogramLayer.toUpperCase()
          this.filterLayers = this.layers.filter( (l) => l.name.indexOf(filterType) !== -1 )

          if( this.filterLayers.length == 0 )
            this.filterLayers = this.layers.filter( (l) => l.name.indexOf('EPC') === -1 )
        },

        _OnCleared() {
            this.layers = null
        },

        _OnToggleLayer(layer, newState) {
            layer.isVisible = newState
            this.$refs.viewer.ShowLayer(layer.name, newState)
        },

        _OnToggleAll(newState) {
            if (this.layers) {
                for (const layer of this.layers) {
                    if (layer.isVisible !== newState) {
                        this._OnToggleLayer(layer, newState)
                    }
                }
            }
        },

        _OnMessage(e) {
            let type = "info"
            // switch (e.detail.level) {
            // case _DxfViewer.MessageLevel.WARN:
            //     type = "warning"
            //     break
            // case _DxfViewer.MessageLevel.ERROR:
            //     type = "negative"
            //     break
            // }
            // this.$q.notify({ type, message: e.detail.message })
        },

        _OnClickDisplayLayerPictogram(type) {
          if (type === this.displayPictogramLayer)
            this.displayPictogramLayer = this.displayDefaultPictogramLayer
          else
            this.displayPictogramLayer = type

          // mandamos a renderizar los layers filtrados por tipo
          this._FilterLayer()
          // mandamos a renderizar

          this.$refs.viewer.Render(this.displayPictogramLayer, this.pictogram.type)
        },

        _onPointerDown(e) {
          console.log('_onPointerDown up: ', e.detail)
          this.$refs.viewer.GetViewer().ResaltarObject(e.detail.domEvent)
        },

        _OnClickZoom(zoomIn) {
          if( zoomIn )
            this.$refs.viewer.ZoomIn()
          else
            this.$refs.viewer.ZoomOut()
        },

        _OnClickSelecting() {
          this.isSelecting = !this.isSelecting
          this.isDraggable = false

          this.$refs.viewer.Dragging(false)
          this.$refs.viewer.Selecting(this.isSelecting, this.workLayer)
        },

        _OnClickUnselect() {
          this.isSelecting = false
          this.isDraggable = false

          this.$refs.viewer.Unselect()
        },

        // toggleFullscreen() {
        _OnClickFullScreen() {
          const element = this.$refs.viewer_page; // El elemento que deseas mostrar en pantalla completa
          // const element = this.$refs.viewer.$el; // El elemento que deseas mostrar en pantalla completa

          if (this.isFullscreen()) {
            this.heightContainerKonva = '75'
            // Salir del modo de pantalla completa
            if (document.exitFullscreen) {
              document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
              document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
              document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
              document.msExitFullscreen();
            }
          } else {
            this.heightContainerKonva = '100'
            // Ingresar al modo de pantalla completa
            if (element.requestFullscreen) {
              element.requestFullscreen();
            } else if (element.webkitRequestFullscreen) {
              element.webkitRequestFullscreen();
            } else if (element.mozRequestFullScreen) {
              element.mozRequestFullScreen();
            } else if (element.msRequestFullscreen) {
              element.msRequestFullscreen();
            }
          }
        },

        _OnFitContent() {
          this.$refs.viewer.FitToScreen()
        },

        async _HandleClickSave() {
          await this.$refs.viewer.Save()

          this.componentViewerKey = 'viewer-konva-' + Math.random().toString(36).substring(7)
        },

        GetModifiedEntities() {
          return this.$refs.viewer.GetModifiedEntities()
        },

        sleep(ms) {
          return new Promise(resolve => setTimeout(resolve, ms));
        },

        isFullscreen() {
          return (
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement
          );
        },

        _IsEnabledFullScreen() {
          if (
            document.fullscreenEnabled ||
            document.webkitFullscreenEnabled ||
            document.mozFullScreenEnabled ||
            document.msFullscreenEnabled
          ) {
            this.fullscreenEnabled = true;
          } else {
            this.fullscreenEnabled = false;
          }
        },

        _SetLayerDisplay() {
          // TODO: de momento agarramos el primer layer, esto cambiar en el futuro para buscar algun atributo que indique
          // que se muestra por defecto
          if (this.pictogram.layers) {
            this.displayPictogramLayer = this.workLayer ? this.workLayer : this.pictogram.layers[0].code;
            this.displayDefaultPictogramLayer = this.displayPictogramLayer
          }
        },

        _GetIcon(layer) {
          return layer.icon ?? 'grip-horizontal' // 'images'
        },

        _OnClickPan() {
          this.isDraggable = !this.isDraggable
          this.isSelecting = false
          this.$refs.viewer.Dragging(this.isDraggable)
          this.$refs.viewer.Selecting(this.isSelecting)
        },

        _OnShowChangeStatuses(e) {
          this.showLayerToolStatuses = e.show
          this.selectedEntities = e.selected_entities
          this.statuses = e.statuses
        },

        _OnShowFormSplit(e) {
          this.showLayerToolSplit = e.show
          this.selectedEntities = e.selected_entities
        },

        _HandleChangeStatus(e) {
          // de momento una vez que cambia de estado solo completamos la acción pasando los datos a la variable que
          // contiene todos los elementos que han cambiado de estado (refierase a la estructura general no a la propiedad)

          this.$refs.viewer.CompleteAction(e)
        },

        handleSplitEntities(e) {
          this.$refs.viewer.SplitEntities(e)
        },

        _HandleOpenPanel(namePanel) {
          if (namePanel === 'panel-views')
            this.showPanelViews = true

          if (namePanel === 'panel-layers')
            this.showLayerPanel = true
        },

        _AllowedAction(action) {
          if (Object.keys(this.canActionsPictogram).length === 0)
            return false;

          return this.canActionsPictogram[action] === 'undefined' ? false : this.canActionsPictogram[action]
        },

        _HandleReturnClick(e) {
          if (this.subactivityId !== null) {
            this.$router.push( {name: 'subactivity.home', params: { subactivity_id: this.subactivityId, activity_id: this.activityId }})
          } else {
            this.$router.push({ name: 'activity.home', params: { activity_id: this.activityId }})
          }
        },

        _HandleClickInfoPictogram(e) {
          this.$refs.refInfoModal.show(this.pictogram, this.displayPictogramLayer)
        }
    },

    created() {
      this.fonts = [mainFont, aux1Font, aux2Font, aux3Font]
      // Estableces capa a motrar por defecto
      this._SetLayerDisplay()

      // Verificar si la API de pantalla completa es compatible en el navegador
      this._IsEnabledFullScreen()
    },
}
</script>

<style scoped>

.epc-toolbar {
  /* bottom: 10px; */
  top: 115px;
  left: 10px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  width: calc(100% - 20px);
  z-index: 5;
}
.epc-toolbar .epc-control-group {
  background-color: rgba(34,34,34,.94);
  box-shadow: 1px 3px 10px 0 rgba(0,0,0,.5);
  color: #f4f4f4;

  border-radius: 5px;
  display: inline-block;
  margin: 0 5px;
  pointer-events: all;
}

.epc-toolbar .epc-control-group .layerTools{
  border-radius: 3px;
  box-shadow: inset 0 2px 2px 0 #000;
  color: #00bfff;
  outline: none;
}

.epc-button{
  border: 1px solid rgba(34,34,34,0);
  border-radius: 4px;
  cursor: pointer;
  float: left;
  /* height: 28px; */
  margin: 4px;
  padding: 6px;
  position: relative;
  /* width: 28px; */
}

.epc-button:hover,
.epc-button.active {
  border: 1px solid #00bfff;
  color: #00bfff;
}

.epc-button.active,
.epc-button:focus {
  border-radius: 3px;
  box-shadow: inset 0 2px 2px 0 #000;
  color: #00bfff;
  outline: none;
}


.docking-panel {
  box-shadow: 1px 3px 10px 0 rgba(0,0,0,.4);
  -webkit-touch-callout: none;
  background: transparent;
  border: none;
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: absolute;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 2;
}

.docking-panel-title {
  background-color: rgba(34,34,34,.94);
  border-bottom: 1px solid rgba(34,34,34,.8);
  color: #fff;

  border: none;
  border-radius: 5px 5px 0 0;
  cursor: move;
  font-size: 20px;
  font-weight: 300;
  line-height: 27px;
  overflow: hidden;
  padding: 11px 0 11px 14px;
  position: relative;
  resize: none;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
  z-index: 1;
}

.docking-panel-delimiter-shadow {
  box-shadow: 0 3px 5px 0 rgba(0,0,0,.1);
}

.docking-panel-close {
  background-position: 0 19px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  width: 25px;
  z-index: 1;
}

.docking-panel-close {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNLjQ1NSAxMC45OTdhLjUuNSAwIDAgMS0uMzEtLjg1bDEwLTEwYS41MDIuNTAyIDAgMCAxIC43MS43MWwtMTAgMTBhLjUuNSAwIDAgMS0uNC4xNFoiIGZpbGw9IiM5NDk0OUYiLz48cGF0aCBkPSJNMTAuNDU3IDExLjAwN2EuNS41IDAgMCAxLS4zMS0uMTVsLTEwLTEwYS41MDIuNTAyIDAgMCAxIC43MS0uNzFsMTAgMTBhLjUuNSAwIDAgMS0uNC44NloiIGZpbGw9IiM5MzlDQTUiLz48L2c+PC9zdmc+);
}

.docking-panel-footer {
  background-color: #222;
}

.docking-panel-footer {
  bottom: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

.docking-panel-footer-resizer {
  background-position-x: 9px;
  background-position-y: 8px;
  background-repeat: no-repeat;
  bottom: 0;
  cursor: pointer;
  height: 20px;
  position: absolute;
  right: 0;
  width: 20px;
}

.docking-panel-footer-resizer {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI3IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSI+PHBhdGggZD0ibS41IDYuNSA2LTZNNC41IDYuNWwxLjUzNi0xLjUzNiIgc3Ryb2tlPSIjMDAwIi8+PHBhdGggZD0ibTEuNSA2LjUgNi02TTUuNSA2LjVsMS41MzYtMS41MzYiIHN0cm9rZT0iI0JFQzhEMiIvPjwvZz48L3N2Zz4=);
  background-position-x: 9px;
  background-position-y: 8px;
  background-repeat: no-repeat;
}

.layers-panel {
  border-color: transparent;
  height: 250px;
  left: 220px;
  min-height: 100px;
  min-width: 250px;
  top: 180px;
  width: 300px;
}

.pressed {
  background-color: rgb(51, 85, 136);
  color: white;
}

.not-pressed {
  background-color: rgba(85, 119, 187, 0.133);
  color: rgb(51, 85, 136);
}

</style>
