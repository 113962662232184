<template>
    <div>
        <!-- Tabla de facturacion-->
        <div class="panel">
            <b-table :data="products" :loading="isLoadingTable" paginated backend-pagination :per-page="perPage"
                :total="total" @page-change="onPageChange" backend-sorting :default-sort="orderField"
                :default-sort-direction="orderDir" @sort="onSort" class="w-full" striped bordered icon-pack="fas"
                detailed detail-key="id" :show-detail-icon="showDetailIcon" scrollable>
                <template>
                    <b-table-column field="id" :label="$t('id')" sortable v-slot="props">
                        {{ props.row.id }}
                    </b-table-column>
                    <b-table-column field="code" :label="$t('invoice_code')" sortable v-slot="props">
                        {{ props.row.code }}
                    </b-table-column>
                    <b-table-column field="provider_name" :label="$t('provider')" sortable v-slot="props">
                        {{ props.row.provider_name }}
                    </b-table-column>
                    <b-table-column field="provider" :label="$t('associated_resources')" v-slot="props">
                        {{ props.row.resources.length }}
                    </b-table-column>
                    <b-table-column field="total_amount" :label="$t('price')" sortable v-slot="props">
                        {{ formatNumber(props.row.total_amount) }}
                    </b-table-column>
                    <b-table-column field="billing_date" :label="$t('invoice_date')" sortable v-slot="props">
                        {{ formatDate(props.row.billing_date) }}
                    </b-table-column>
                    <b-table-column field="due_date" :label="$t('due_date')" sortable v-slot="props">
                        {{ formatDate(props.row.due_date) }}
                    </b-table-column>

                    <b-table-column field="actions" :label="$t('actions')" v-slot="props" centered>
                        <div class="w-full h-full flex items-center justify-around">
                            <b-tooltip :label="$t('edit_billing')" position="is-left">
                                <div @click="editDetalilButtonClicked(props.row)" class="cursor-pointer flex text-blue">
                                    <b-icon pack="fas" icon="file-pen" />
                                </div>
                            </b-tooltip>
                            <b-tooltip :label="$t('remove_billing')" position="is-left" type="is-danger">
                                <div @click="removeBilling(props.row)" class="cursor-pointer flex text-red">
                                    <b-icon pack="fas" icon="trash-alt" />
                                </div>
                            </b-tooltip>
                        </div>
                    </b-table-column>
                </template>
                <template #detail="props">
                    <div v-if="props.row.resources.length > 0">
                        <b-table :data="props.row.resources" :loading="isLoadingTable" backend-sorting class="w-full"
                            striped bordered icon-pack="fas" detailed detail-key="product_id"
                            :show-detail-icon="showDetailIcon" scrollable>
                            <template>
                                <b-table-column field="product_name" :label="$t('breakdown')" v-slot="data">
                                    {{ data.row.product_name }}
                                </b-table-column>
                                <b-table-column field="unit_price" :label="$t('unit_price')" v-slot="data" centered>
                                    {{ !!data.row.only_price ? " - " : formatNumber(data.row.unit_price) }}
                                </b-table-column>
                                <b-table-column field="unit_price" :label="$t('cost_price')" v-slot="data" centered>
                                    {{ !!data.row.only_price ? formatNumber(data.row.unit_price) : "-" }}
                                </b-table-column>
                                <b-table-column field="quantity" :label="'U'" v-slot="data" centered>
                                    {{ data.row.quantity === 0 ? '-' : formatNumber(data.row.quantity) }}
                                </b-table-column>
                                <b-table-column field="subtotal" :label="$t('total')" v-slot="data" centered>
                                    {{ formatNumber(data.row.subtotal) }}
                                </b-table-column>
                                <b-table-column field="assigned_quantity" :label="$t('assigned_quantity')" v-slot="data"
                                    centered>
                                    {{
                                        !!data.row.only_price
                                            ? "-" : calculateTotalQuantity(data.row.invoice_assignments) + "/" +
                                            data.row.quantity }}
                                </b-table-column>
                                <b-table-column field="assigned_quantity" :label="$t('Coste asignado')" v-slot="data"
                                    centered>
                                    {{
                                        !!data.row.only_price ? calculateTotalQuantity(data.row.invoice_assignments) + "/"
                                            + data.row.unit_price : "-"
                                    }}
                                </b-table-column>
                                <b-table-column field="actions" :label="$t('actions')" v-slot="data">
                                    <div class="w-full h-full flex items-center justify-center">
                                        <b-tooltip :label="$t('add_assignment')" position="is-left">
                                            <div @click="addAssignment(data.row, data.row.invoice_item_id)"
                                                class="cursor-pointer flex text-dark">
                                                <b-icon pack="fas" icon="circle-plus" />
                                            </div>
                                        </b-tooltip>
                                    </div>
                                </b-table-column>
                            </template>
                            <template #detail="data">
                                <table class="table ">
                                    <tbody>
                                        <tr class="bg-gray-200"
                                            v-for="(assigment, index) in data.row.assignments_cost_budget" :key="index">
                                            <td><strong>{{ $t('assignment') }}: </strong> {{ assigment.assignment_name
                                                }}
                                            </td>
                                            <td><strong>{{ $t('quantity') }}: </strong>{{
                                                data.row.invoice_assignments[index].quantity }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </template>
                        </b-table>
                    </div>
                    <div v-else>
                        <p>No hay recursos asociados a la factura</p>
                    </div>

                </template>
                <template #empty>
                    <div class="has-text-centered">{{ $t('no_results') }}</div>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from 'axios'
import { parseQuantity } from './helpers.js'

export default {
    props: {
        projectId: { type: Number, require: true },
    },

    computed: {
        ...mapGetters({
            company: "app/company",
        }),

        filters() {
            let filters = {
                'order_field': this.orderField,
                'order_dir': this.orderDir,
                'perPage': this.perPage,
                'page': this.page,
                'project_id': this.projectId
            }
            return filters
        },
        totalQuantity() {
            return this.assignments.reduce((sum, item) => sum + item.quantity, 0);
        }
    },

    data: function () {
        return {
            isLoadingTable: true,
            products: [],
            perPage: 25,
            orderField: 'code',
            orderDir: 'asc',
            page: 1,
            last: 1,
            total: 0,
            applyFilters: {},
            showDetailIcon: true,
            quantityAssignment: 0,
            assignments: []
        }
    },

    created() {
        this.getInvoice()
    },

    methods: {
        async getInvoice(filters = null) {
            this.isLoadingTable = true;
            this.products = [];
            const params = filters ? filters : this.filters;

            try {
                const { data } = await axios.get(`/api/v2/invoices`, { params });

                if (data && data.success) {
                    this.products = data.invoices;
                    // this.assignments = data.invoices.resources.assignments;
                    if (data.invoices.resources && data.invoices.resources.assignments) {
                        this.assignments = data.invoices.resources.assignments;
                    } else {
                        this.assignments = [];
                    }
                    this.last = data.last_page;
                    this.total = data.total;

                }
            } catch (error) {
                throw ('Error fetching inventory:', error);
            } finally {
                this.isLoadingTable = false;
            }
        },

        onSort(field, order) {
            this.orderField = field;
            this.orderDir = order;
            this.getInvoice(this.filters);
        },

        onPageChange(page) {
            this.page = page;
            this.getInvoice();
        },

        reload(arg = {}) {
            let filters = { ...arg, ...this.filters }
            this.getInvoice(filters);
        },

        async editButtonClicked(entity) {
            this.$emit('epc-edit', entity)
        },

        archiveClass(value) {
            return value ? "cursor-pointer flex text-yellow-dark" : "cursor-pointer flex text-blue"
        },

        handleSelect(newRow) {
            this.$emit('update:template', newRow);
        },

        clearSelected() {
            this.rowSelected = null
        },

        showQuantity(quantity) {
            return parseQuantity(quantity);
        },
        addAssignment(resource, invoiceId) {
            this.$emit('add', resource, invoiceId);
        },
        async loadAssignmentsQuantity(productId) {
            try {
                const response = await axios.get(`/api/v2/invoice/cost-budgets/${productId}/assignments`);
                this.quantityAssignment = response.data.quantities[0].quantity;
            } catch (error) {
                console.error('Error loading assignments:', error);
            }
        },
        formatNumber(value) {
            const number = parseFloat(value);
            if (isNaN(number)) return '0,00';
            let [integerPart, decimalPart] = number.toFixed(2).split('.');
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            return `${integerPart},${decimalPart}`;
        },
        async removeBilling(invoice) {
            this.$dialog.confirm({
                title: this.$t("delete_billing"),
                message: this.$t('delete_billing_confirm_text', [invoice.code]),
                confirmText: this.$t("delete"),
                type: "is-danger",
                hasIcon: true,
                onConfirm: async () => {
                    this.isLoading = true;
                    let url = "/api/v2/invoices/" + invoice.id;
                    const { data } = await axios.delete(url);
                    if (data.success) {
                        this.$toast.open({
                            message: this.$t("removed_successfully"),
                            type: "is-success",
                            position: "is-top-right"
                        });
                        this.getInvoices();
                    } else {
                        this.isLoading = false;
                        this.$toast.open({
                            message: this.$t("remove_error"),
                            type: "is-danger",
                            position: "is-top-right"
                        });
                    }
                }
            });
        },
        calculateTotalQuantity(assignments) {
            if (!Array.isArray(assignments)) return 0;
            return assignments.reduce((sum, assignment) => sum + (assignment.quantity || 0), 0);
        },
        formatDate(date) {
            if (!date) return '';
            const [year, month, day] = date.split('-');
            return `${day}-${month}-${year}`;
        },
        editDetalilButtonClicked(billing) {
            this.$router.push({
                name: 'project.admin.cost-management.billing',
                params: {
                    projectId: this.projectId,
                    billing: billing
                }
            })
        },

    },

};
</script>
<style scoped>
.table {
    border-collapse: none;
}

.table td,
.table th {
    border: 1px solid #E2E8F0;
    border-width: 1px;
    padding: 0.5em 0.75em;
    vertical-align: top;
}

</>
