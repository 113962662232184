<template>
    <div>
        <div class="top-bar flex justify-end">
            <v-button @click="() => { showFormImportEntities = true }" icon="file-import" class="mr-4"
                :disabled="showFormData">{{ $t('import_warehouses') }}</v-button>

            <v-button @click="openWarehouseModal()" icon="warehouse" :disabled="showFormData">{{
                $t('add_warehouse') }}</v-button>

            <warehouse-modal ref="warehouse_modal" :projectId="projectId" @reload-list="handleSuccess"
                @epc-store="handleAddWarehouse" />
        </div>
        <div class="flex">
            <div :class="['w-full', 'mr-2']">
                <warehouse-table ref="warehouse_table" :projectId="projectId" @epc-edit="handleEditEntity" />
            </div>
            <transition name="fade">
                <div class="w-5/12 ml-2" v-if="showFormImportEntities">
                    <warehouses-import :projectId="projectId" :entity-type="'warehouses'"
                        @epc-close="(event) => { showFormImportEntities = false }"
                        @epc-success-import="() => { showFormImportEntities = false; $refs.warehouse_table.reload() }" />
                </div>
            </transition>
        </div>
    </div>

</template>

<script>
import WarehouseTable from './warehouse-table.vue';
import WarehouseModal from './warehouse-modal.vue';
import WarehousesImport from './warehouses-import.vue';
export default {
    components: {
        WarehouseTable,
        WarehouseModal,
        WarehousesImport
    },
    props: {
        projectId: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            showFormImportEntities: false,
            showFormData: false,
        }
    },
    methods: {
        openWarehouseModal(entity = null) {
            let warehouse = {
                id: null,
                code: "",
                name: "",
                description: "",
                location: "",
            };

            if (entity !== null) {
                warehouse.id = entity.id;
                warehouse.code = entity.code;
                warehouse.name = entity.name;
                warehouse.description = entity.description;
                warehouse.location = entity.location;
            }

            this.$refs.warehouse_modal.show(warehouse);
        },
        handleSuccess() {
            this.$refs.warehouse_table.reload()
            // this.$emit('epc-updated-list')
        },

        handleEditEntity(entity) {
            this.openWarehouseModal(entity)
        },
        handleAddWarehouse() {
            this.$emit('epc-store-warehouse');
        }
    },

}
</script>

<style lang="scss" scoped></style>