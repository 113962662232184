import axios from "axios"
import { Application, Container, EventSystem, Graphics, InteractionManager, Point } from 'pixi.js';
import { Pic } from "./Pic";
import { PicLabel } from "./PicLabel";
import { PicBackground } from "./PicBackground";
import { PicComplete } from "./PicComplete";
import { Viewport } from "./Viewport";
import { PicNode } from "./PicNode";

/** Level in "message" events. */
const MessageLevel = Object.freeze({
    INFO: "info",
    WARN: "warn",
    ERROR: "error"
})

export class PixiViewer {

    constructor(domContainer, stage, options = null) {
      this.options = Object.create(PixiViewer.DefaultOptions)
      if (options) {
          Object.assign(this.options, options)
      }
      options = this.options

      this.domContainer = domContainer
      this.stage = stage

      // Create a PixiJS application.
      this.app = null
      this.picsContainer = null
      this.backsContainer = null
      this.globalContainer = null

      this.entities = []
      this.labels = []
      this.bounds = null
      this.origin = null
      this.layers = []

      this.zoomLevel = 1
      this.dragging = false
      this.selecting = false
      this.workLayer = null

      this.boxSelect = null
      this.selectionBoxStartX = 0;
      this.selectionBoxStartY = 0;
      this.selectionBoxEndX = 0;
      this.selectionBoxEndY = 0;
      this.selectionBoxWidth = 0;
      this.selectionBoxHeight = 0;

      this.dragData = null

      this.renderer = null

      // this.layerEntities = this.stage.getLayers().find( (ly) => { return ly.attrs.id == 'layerEntities' } ).getLayer()
      // this.canvaEntities = this.layerEntities ? this.layerEntities.getNativeCanvasElement() : null

      // if (options.autoResize) {
        // this.canvasWidth = domContainer.clientWidth
        this.canvasWidth = domContainer.offsetWidth
        // this.canvasHeight = domContainer.clientHeight
        this.canvasHeight = domContainer.offsetHeight

        domContainer.style.position = "relative"
      // } else {
      //     this.canvasWidth = options.canvasWidth
      //     this.canvasHeight = options.canvasHeight
      //     this.resizeObserver = null
      // }

      this._SetSize(this.canvasWidth, this.canvasHeight)

      // domContainer.style.display = "block"
      // if (options.autoResize) {
      //     this.canvas.style.position = "absolute"
      //     this.resizeObserver = new ResizeObserver(entries => this._OnResize(entries[0]))
      //     this.resizeObserver.observe(domContainer)
      // }



      // Evento de scroll del mouse para controlar el zoom
      // window.addEventListener('wheel', (e) => { this._HandleWheel(e) })
      window.addEventListener('resize', () => { this._OnResize() })

      this._onMouseDown2 = this._onMouseDown2.bind(this);
      this._onMouseMove2 = this._onMouseMove2.bind(this);
      this._onMouseUp2 = this._onMouseUp2.bind(this);

      this._Init()
    }

    async _Init() {
      this.app = new Application();

      // Intialize the application.
      await this.app.init({
        background: '#f5f5f5',
        resizeTo: this.domContainer, //window,
        hello: true,
        preference: 'webgl',
        antialias: true,
        sharedTicker: true,
        // resolution: window.devicePixelRatio || 1,
        resolution: 1,
        width: this.canvasWidth,
        height: this.canvasHeight,
        useBackBuffer: true,
      });

      this.app.renderer.resize(this.canvasWidth, this.canvasHeight);
      this.stage.appendChild(this.app.canvas);

      this.viewport = new Viewport(this.app.renderer, this.canvasWidth, this.canvasHeight, this.app.stage)
      this.viewport.resize()

      this.globalContainer = new Container({
      })

      this.backsContainer = new Container({
        x: 10,
        y: 10,
        isRenderGroup: true,
        interactiveChildren: false,
      });

      this.picsContainer = new Container({
        x: 10,
        y: 10,
        interactive: true,
      });

      this.globalContainer.addChild(this.backsContainer, this.picsContainer);
      this.app.stage.addChild(this.globalContainer);


      // // Agregar evento de clic al contenedor
      this.app.stage.eventMode = 'static';
      this.app.stage.hitArea = this.app.screen;
      this.app.stage.on('pointerdown', this._onMouseDown2);

      // this.globalContainer.eventMode = 'static';
      // this.globalContainer.hitArea = this.app.screen;
      // this.globalContainer.on('pointerdown', this._onMouseDownGlobalContainer);

      // caja de seleccion
      this.boxSelect = document.createElement('div')
      this.boxSelect.setAttribute('id', 'tpSelectBox')
      this.boxSelect.style.position = 'absolute'
      this.boxSelect.style.display = 'none'
      this.boxSelect.style.pointerEvents = 'none'
      // this.boxSelect.style.border = '1px solid black'

      this.stage.appendChild(this.boxSelect)

      // this.app.ticker.add(() => {
      //   this.UpdateViewportCulling(this.app.screen);
      // });
    }

    // Manejador de eventos para clic del ratón
    _onMouseDown2(event) {

      if( this.selecting ) {
        // Guardar las coordenadas del clic inicial
        this.selectionBoxStartX = event.data.global.x;
        this.selectionBoxStartY = event.data.global.y;

        this.boxSelect.style.left = `${this.selectionBoxStartX}px`
        this.boxSelect.style.top = `${this.selectionBoxStartY}px`
        this.boxSelect.style.display = 'block'

        this.boxSelect.style.backgroundColor = 'rgba(12,94,149, 0.7)' //'#0c5e95'

        // Verificar si las coordenadas están dentro del área seleccionable
        //   if (selectableArea.containsPoint(new PIXI.Point(x, y))) {
        //     // Cambiar el color del área seleccionable (por ejemplo, a verde)
        //     selectableArea.tint = 0x00FF00;
        // } else {
        //     // Restaurar el color original del área seleccionable (azul)
        //     selectableArea.tint = 0x0000FF;
        // }

        // Escuchar eventos de movimiento del ratón y liberación del clic
      }

      if( this.dragging ) {
        this.dragData = event.data
        this.dragData.originalPosition = event.data.getLocalPosition(this.globalContainer.parent);
        this.dragData.originalPosition.x -= this.globalContainer.x;
        this.dragData.originalPosition.y -= this.globalContainer.y;
      }

      this.app.stage.on('pointerup', this._onMouseUp2);
      this.app.stage.on('pointermove', this._onMouseMove2);
    }

    // Manejador de eventos para movimiento del ratón
    _onMouseMove2(event) {

      if(this.selecting) {
        // Calcular el tamaño del área de selección
        this.selectionBoxEndX = event.data.global.x
        this.selectionBoxEndY = event.data.global.y

        this.selectionBoxWidth = this.selectionBoxEndX - this.selectionBoxStartX;
        this.selectionBoxHeight = this.selectionBoxEndY - this.selectionBoxStartY;

        this.boxSelect.style.width = `${this.selectionBoxWidth}px`
        this.boxSelect.style.height = `${this.selectionBoxHeight}px`
      }

      if( this.dragging && this.dragData ) {
          const newPosition = this.dragData.getLocalPosition(this.globalContainer.parent);
          this.globalContainer.x = newPosition.x - this.dragData.originalPosition.x;
          this.globalContainer.y = newPosition.y - this.dragData.originalPosition.y;
      }
    }

    // Manejador de eventos para liberación del clic
    _onMouseUp2(e) {

      if (this.selecting) {
        // Detener de escuchar eventos de movimiento del ratón y liberación del clic
        let self = this

        let pointStart = this.picsContainer.toLocal(new Point(self.selectionBoxStartX,self.selectionBoxStartY))
        let pointEnd = this.picsContainer.toLocal(new Point(self.selectionBoxEndX,self.selectionBoxEndY))

        if (this.workLayer !== 'nodes') {
          let pics=this.picsContainer.children.filter( (el) => {

            // Verificar si el objeto está completamente dentro del área de selección
            if (
              el.picX <= pointEnd.x
              && el.picX + el.picWidth >= pointStart.x
              && el.picY <= pointEnd.y
              && el.picY + el.picHeight >= pointStart.y
              ) {
                if( ! el.picSelected )
                  el.SelectPic(el)
              return true;
            }

            // Si no, el objeto no está completamente dentro del área de selección
            return false;

          } );
        } else {
          this.picsContainer.children.filter( (el) => {
            return el.picType === this.workLayer
          } ).forEach(p => {
            let b = p.getBounds()

            // let circleLeft = b.x;
            // let circleRight = b.x + b.width;
            // let circleTop = b.y;
            // let circleBottom = b.y + b.height;

            let circleLeft = p.picX - p.picRadius;
            let circleRight = p.picX + p.picRadius;
            let circleTop = - p.picY - p.picRadius;
            let circleBottom = p.picX + p.picRadius;

            // Verificar si el objeto está completamente dentro del área de selección
            if (
              circleLeft <= pointEnd.x
              && circleRight >= pointStart.x
              && circleTop <= pointEnd.y
              && circleBottom >= pointStart.y
              ) {
                if (! p.picSelected)
                  p.SelectPic(p)
            }

          });
        }

        this.selectionBoxStartX = 0
        this.selectionBoxStartY = 0
        this.selectionBoxEndX = 0
        this.selectionBoxEndY = 0
        this.selectionBoxHeight = 0
        this.selectionBoxWidth = 0

        this.boxSelect.style.display = 'none'
        this.boxSelect.style.width = 0
        this.boxSelect.style.height = 0


      }

      if(this.dragging && this.dragData) {
        this.dragData = null
      }

      this.app.stage.off('pointermove', this.onMouseMove2);
      this.app.stage.off('pointerup', this.onMouseUp2);
    }

    // para zoom stage
    // TODO: SIRVE PARA ZOOM DE LA ESCENA CON PIXIJS
    ZoomStage(scaleBy) {

      // const centralPoint = {
      //   x: (this.app.renderer.width - this.picsContainer.width) / 2,
      //   y: (this.app.renderer.height - this.picsContainer.height) / 2
      // };

      this.zoomLevel += scaleBy;

      // const newCentralPoint = {
      //   x: (centralPoint.x - this.picsContainer.position.x) * this.app.stage.scale.x,
      //   y: (centralPoint.y - this.picsContainer.position.y) * this.app.stage.scale.y
      // };

      // this.app.stage.scale.set(this.zoomLevel, this.zoomLevel);
      this.globalContainer.scale.set(this.zoomLevel, this.zoomLevel);
    }

    UpdateViewportCulling(viewport) {
      const viewportBounds = viewport.getBounds();

      this.picsContainer.children.forEach(object => {
          const objectBounds = object.getBounds();

          // Check if object is within viewport bounds
          const isVisible = objectBounds.x + objectBounds.width >= viewportBounds.x &&
                            objectBounds.x <= viewportBounds.x + viewportBounds.width &&
                            objectBounds.y + objectBounds.height >= viewportBounds.y &&
                            objectBounds.y <= viewportBounds.y + viewportBounds.height;

          // Set renderable property based on visibility
          object.renderable = isVisible;
      });
    }


    async TakeScreenshot() {

        this.app.stop();
        // Cambiar la resolución del renderizador
        this.app.renderer.resize(this.app.screen.width, this.app.screen.height, 8); //8=resolution

        const url = await this.app.renderer.extract.base64(this.app.stage);

        let image = await this._getBase64Image(url).then()

        const screenshot = this.Base64ToBlob(image.replace('data:image/png;base64,', ''), 'image/png')

        this.app.renderer.resize(this.app.screen.width, this.app.screen.height, 1);

        this.app.start();

        return screenshot
    }

    _getBase64Image(url) {
      const img = document.createElement("img");
      return new Promise((resolve, reject)=>{
        img.src = url,
        img.addEventListener("load", ()=>{
            const canva = document.createElement("canvas")
            canva.width = img.width,
            canva.height = img.height;
            canva.getContext("2d").drawImage(img, 0, 0, img.width, img.height);
            resolve(canva.toDataURL("image/png"))
        })

        img.addEventListener("error", ()=>{
            reject()
        })
      })
    }


    Base64ToBlob(base64String, contentType) {
      contentType = contentType || '';
      var sliceSize = 1024;
      var byteCharacters = atob(base64String);
      var byteArrays = [];

      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);

        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: contentType });
    }















    _SetSize(width, height) {
      this.stage.width = width
      this.stage.height = height
    }

    SetScene(scene) {
      this.entities = scene.entities
      this.labels = scene.texts
      this.bounds = scene.bounds
      this.origin = scene.origin
      this.layers = scene.layers
    }

    SetSize(width, height) {
      this._EnsureRenderer()

      const hScale = width / this.canvasWidth
      const vScale = height / this.canvasHeight

      const cam = this.camera
      const centerX = (cam.left + cam.right) / 2
      const centerY = (cam.bottom + cam.top) / 2
      const camWidth = cam.right - cam.left
      const camHeight = cam.top - cam.bottom
      cam.left = centerX - hScale * camWidth / 2
      cam.right = centerX + hScale * camWidth / 2
      cam.bottom = centerY - vScale * camHeight / 2
      cam.top = centerY + vScale * camHeight / 2
      cam.updateProjectionMatrix()

      this.canvasWidth = width
      this.canvasHeight = height
      this.renderer.setSize(width, height)

      this._Emit("resized", {width, height})
      this._Emit("viewChanged")
      this.Render()
    }

    /** @return {Iterable<{name:String, color:number}>} List of layer names. */
    GetLayers() {
      if (this.layers === undefined) return []

      const result = []
      for (const lyr of this.layers.values()) {
          result.push({
              name: lyr.name,
              displayName: lyr.displayName,
              color: this._TransformColor(lyr.color)
          })
      }
      return result
    }

    ShowLayer(name, show) {
        this._EnsureRenderer()
        const layer = this.layers.get(name)
        if (!layer) {
            return
        }
        for (const obj of layer.objects) {
            obj.visible = show
        }
        this.Render()
    }

    /** Reset the viewer state. */
    Clear() {
        // this._EnsureRenderer()
        // if (this.worker) {
        //     this.worker.Destroy(true)
        //     this.worker = null
        // }
        // if (this.controls) {
        //     this.controls.dispose()
        //     this.controls = null
        // }
        // this.scene.clear()
        // for (const layer of this.layers.values()) {
        //     layer.Dispose()
        // }
        // this.layers.clear()
        // this.blocks.clear()
        // this.materials.each(e => e.material.dispose())
        // this.materials.clear()
        // this.SetView({x: 0, y: 0}, 2)
        // this._Emit("cleared")
        // this.Render()
    }

    /** Free all resources. The viewer object should not be used after this method was called. */
    Destroy() {
        // if (!this.HasRenderer()) {
        //     return
        // }
        // if (this.resizeObserver) {
        //     this.resizeObserver.disconnect()
        // }
        this.Clear()
        // this._Emit("destroyed")
        // for (const m of this.simplePointMaterial) {
        //     m.dispose()
        // }
        // for (const m of this.simpleColorMaterial) {
        //     m.dispose()
        // }
        // this.simplePointMaterial = null
        // this.simpleColorMaterial = null
        // this.renderer.dispose()
        // this.renderer = null
        this.stage.destroy()
        this.stage = null
    }

    SetView() {
      // Dimensiones de la ventana de visualización en PixiJS
      const windowWidth = this.canvasWidth /* ancho de la ventana en píxeles */;
      const windowHeight = this.canvasHeight /* alto de la ventana en píxeles */;
      let offset = this._GetOffset()

      // Dimensiones del diseño en AutoCAD
      const autocadWidth = this.bounds ? this.bounds.maxX - this.bounds.minX + offset.x : windowWidth;
      const autocadHeight = this.bounds ? this.bounds.maxY - this.bounds.minY  + offset.y : windowHeight;

      // Calcular la escala en X y en Y
      const scaleX = windowWidth / autocadWidth;
      const scaleY = windowHeight / autocadHeight;

      // Usar la escala más pequeña para mantener la relación de aspecto
      const scale = Math.min(scaleX, scaleY);

      this.backsContainer.scale.set(scale);
      this.picsContainer.scale.set(scale);

      this.backsContainer.position.set((
        this.app.renderer.width - this.picsContainer.width) / 2,
        (this.app.renderer.height - this.picsContainer.height) / 2
      )

      this.picsContainer.position.set((
        this.app.renderer.width - this.picsContainer.width) / 2,
        (this.app.renderer.height - this.picsContainer.height) / 2
      )
    }

    EnableDraggable(enable) {
      this.stage.draggable(enable)
    }

    /** Set view to fit the specified bounds. */
    FitView(padding = 0.1) {

      // Dimensiones de la ventana de visualización en PixiJS
      const windowWidth = this.app.renderer.width;
      const windowHeight = this.app.renderer.height;

      // Calcular la escala necesaria para que los límites de la escena se ajusten a la ventana de visualización
      const scaleX = windowWidth / (this.bounds.maxX - this.bounds.minX);
      const scaleY = windowHeight / (this.bounds.maxY - this.bounds.minY);
      const scale = Math.min(scaleX, scaleY); // Usar la escala más pequeña para mantener la relación de aspecto

      // Calcular la posición necesaria para centrar la escena en la ventana de visualización
      const offsetX = (windowWidth - (this.bounds.maxX - this.bounds.minX) * scale) / 2 - this.bounds.minX * scale;
      const offsetY = (windowHeight - (this.bounds.maxY - this.bounds.minY) * scale) / 2 - this.bounds.minY * scale;

      // Aplicar la escala y la posición al contenedor principal o a la cámara
      this.app.stage.scale.set(scale);
      this.picsContainer.position.set(offsetX, offsetY);

        // const aspect = this.canvasWidth / this.canvasHeight
        // let width = maxX - minX
        // const height = maxY - minY
        // const center = {x: minX + width / 2, y: minY + height / 2}

        // if (height * aspect > width) {
        //     width = height * aspect
        // }

        // if (width <= Number.MIN_VALUE * 2) {
        //     width = 1
        // }

        // this.SetView(center, width * (1 + padding))
    }

    FitViewKonvaNew(padding = 0.1) {
      const container = this.domContainer;

      // Calcula el tamaño del contenedor
      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;

      const stageWidth = this.stage.width()
      const stageHeight = this.stage.height()

      // Calcula la escala para ajustar el Stage al contenedor
      const scaleX = containerWidth / stageWidth;
      const scaleY = containerHeight / stageHeight;
      const scale = Math.min(scaleX, scaleY);

      // Escala el Stage y actualiza su tamaño
      this.stage.width(stageWidth * scale);
      this.stage.height(stageHeight * scale);

      // Centra el Stage en el contenedor
      this.stage.scale({ x: scale, y: scale });
      this.stage.offset({
        x: (stageWidth - stageWidth * scale) / 2,
        y: (stageHeight - stageHeight * scale) / 2,
      });

      // Dibujar los elementos del Stage actualizados
      this.stage.draw();
    }

    FitViewKonva(padding = 0.1) {

      // now we need to fit stage into parent container
      var containerWidth = this.domContainer.offsetWidth;
      var containerHeight = this.domContainer.offsetHeight;

      // but we also make the full scene visible
      // so we need to scale all objects on canvas
      var scaleX = containerWidth / this.stage.width();
      var scaleY = containerHeight / this.stage.height();
      // let scaleX = this.canvaEntities.width / this.stage.width();
      // let scaleY = this.canvaEntities.height / this.stage.height();
      const scale = Math.min(scaleX, scaleY);

      this.stage.width(this.stage.width() * scale);
      this.stage.height(this.stage.height() * scale);

      // this.stage.width(this.stage.width() * scaleX);
      // this.stage.height(this.stage.height() * scaleY);


      this.stage.scale({ x: scaleX, y: scaleY });

      let center = this._FitViewKonva(this.stage.width(), this.stage.height())
      let offset = this._GetOffset()
      // this.stage.position({ x: Math.abs(center.x + offset.x), y: Math.abs(center.y - offset.y) })
      this.stage.position({ x: Math.abs(center.x + offset.x), y: Math.abs(center.y) })
      // this.stage.position({ x: center.x, y: center.y })
      this.stage.draw()


      // this.ZoomStage(0.8) // ultimo recurso harcodeado
    }

    _FitViewKonva(canvasWidth, canvasHeight, padding = 0.1) {
      let minX = this.bounds.minX - this.origin.x
      let maxX = this.bounds.maxX - this.origin.x
      let minY = this.bounds.minY - this.origin.y
      let maxY = this.bounds.maxY - this.origin.y

      const aspect = canvasWidth / canvasHeight
      let width = maxX - minX
      const height = maxY - minY
      const center = {x: minX + width / 2, y: minY + height / 2}

      if (height * aspect > width) {
          width = height * aspect
      }

      if (width <= Number.MIN_VALUE * 2) {
          width = 1
      }

      return center
      // this.SetView(center, width * (1 + padding))
    }

    GetScale() {
      let canvasWidth = this.stage.width()
      let canvasHeight = this.stage.height()

      return {
        x: Math.round(canvasWidth / ((this.bounds.maxX  - this.origin.x) - (this.bounds.minX  - this.origin.x))),
        y: Math.round(canvasHeight / ((this.bounds.maxY  - this.origin.y) - (this.bounds.minY  - this.origin.y)))
      }
    }

    SetScaleStage() {
      let scale = this.GetScale()
      this.stage.scaleX (scale.x)
      this.stage.scaleY (scale.y)
    }

    /** @return {Vector2} Scene origin in global drawing coordinates. */
    GetOrigin() {
        return this.origin
    }

    // Este metodo debe recibir los layer que son controlados por nosotros para determinar el color del estado
    GetEntities(layers, typeRegister) {
      let labels = []
      let entities = [];
      let backgrounds = []
      let completed = []

      let offset = this._GetOffset();

      // console.log('entites: los mas comono: ', this.entities)
      Object.keys(this.entities).forEach((key, index) => {
        const element = this.entities[key]
        if(element) {
          if (element.type === 'structures') {

            if (typeRegister !== 'issue')
              this.backsContainer.addChild( new PicBackground(element, offset, index) )

            let picComplete = new PicComplete(element, offset, layers, index)
            this.backsContainer.addChild( picComplete )
            completed.push(picComplete)

            this.backsContainer.addChild( new PicLabel(element, offset, index) )
          }

          entities.push( new Pic(element, offset, layers, this.layers, index, typeRegister) )
        }
      });

      this.entities = entities

      return { labels, entities, backgrounds, completed }
    }

    GetEntitiesEvacuationLine(layers) {
      let labels = []
      let entities = [];
      let backgrounds = []
      let completed = []

      let offset = this._GetOffset();

      // console.log('entites: los mas comono: ', this.entities)
      Object.keys(this.entities).forEach((key, index) => {
        const element = this.entities[key]
        if(element) {
          if (element.type === 'nodes') {

            // this.backsContainer.addChild( new PicBackground(element, offset, index) )
            // let picComplete = new PicComplete(element, offset, layers, index)
            // this.backsContainer.addChild( picComplete )
            // completed.push(picComplete)

            entities.push( new PicNode(element, offset, layers, this.layers, index) )
            this.backsContainer.addChild( new PicLabel(element, offset, index) )
          } else
            entities.push( new Pic(element, offset, layers, this.layers, index) )
        }
      });

      this.entities = entities

      return { labels, entities, backgrounds, completed }
    }

    Render(type, typePictogram='photovoltaic_park') {
      this.picsContainer.removeChildren()

      if ('photovoltaic_park' === typePictogram) {
        this.entities.filter( el => el.picType == type )
          .forEach((el) => { this.picsContainer.addChild(el) } )
      } else {
        this.entities
          .forEach((el) => {
            if (el.picType !== type)
              el.interactive = false
            else
              el.interactive = true
            this.picsContainer.addChild(el)
          } )
      }

      this.SetView()

    }

    GetEntitiesDesign(type) {
      return this.picsContainer.children.filter( el => el.picType == type )
    }

    _HandleWheel(e) {
      // Incrementar o disminuir el nivel de zoom en función de la dirección del scroll
      const delta = e.deltaY > 0 ? -0.1 : 0.1;
      this.zoomLevel += delta;

      // Limitar el nivel de zoom entre ciertos límites (opcional)
      this.zoomLevel = Math.max(0.1, Math.min(10, this.zoomLevel)); // Zoom mínimo: 0.1, Zoom máximo: 3

      // Aplicar el zoom
      this.applyZoom(this.zoomLevel);
    }

    // Función para aplicar el zoom
    applyZoom(scale) {
      // Escalar el contenido del lienzo (canvas)
      this.app.stage.scale.set(scale);
    }

    _GetNameEntity(entity, index) {
      if ( entity.name != null )
        return entity.name

      return 'E.' + entity.type.charAt(0).toUpperCase() + '.' + (index + 1)

    }

    _GetColoStatusEntityOnlyComplete(entity, layers) {
      if( entity.status != null ) {
        let lyr = layers.find( item => { return item.code === entity.type })
        if (lyr) {
          let idStatus = typeof entity.status === 'object' ? entity.status.id : entity.status;

          let status = lyr.statuses.find(item => { return item.id == idStatus && item.is_completion == 1})
          if (status)
            return status.color
        }
      }
     return null
   }

    _GetColorStatusOfLayout(entity, layers) {
       if( entity.status != null ) {
         let lyr = layers.find( item => { return item.code === entity.type })
         if (lyr) {
           let idStatus = typeof entity.status === 'object' ? entity.status.id : entity.status;

           let status = lyr.statuses.find(item => { return item.id == idStatus })
           if (status)
             return status.color
         }
       }
      return null

      // if ( typeof entity.status === 'object' )
      //   return entity.status.color
      // else {
      //   let lyr = layers.find( item => { return item.code === entity.type })
      //   if (lyr) {
      //     let status = lyr.statuses.find(item => { return item.id == entity.status })
      //     if (status) return status.color
      //   }
      //   return null
      // }
    }

    // zoom usado por evento Wheel
    ZoomStagePoiter(e, scaleBy) {
      var oldScale = this.stage.scaleX();
      var pointer = this.stage.getPointerPosition();

      var mousePointTo = {
        x: (pointer.x - this.stage.x()) / oldScale,
        y: (pointer.y - this.stage.y()) / oldScale,
      };

      // how to scale? Zoom in? Or zoom out?
      let direction = e.evt.deltaY > 0 ? 1 : -1;

      // when we zoom on trackpad, e.evt.ctrlKey is true
      // in that case lets revert direction
      if (e.evt.ctrlKey) {
        direction = -direction;
      }

      var newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

      this.stage.scale({ x: newScale, y: newScale });

      var newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };
      this.stage.position(newPos);
    }

    Screenshot() {
      return this.stage.toDataURL({
        // mimeType: 'image/jpeg',
        quality: 1,
        pixelRatio: 5,
        // width: 1280,
        // height: 720
      })
    }

    FitToScreen() {
      return new Promise((resolve, reject) => {

        // // Dimensiones de la ventana de visualización en PixiJS
        // const bounds = this._GetOffset()
        // // const windowWidth = this.app.renderer.width;
        // // const windowHeight = this.app.renderer.height;

        // const windowWidth = this.canvasWidth;
        // const windowHeight = this.canvasHeight;

        // // Calcular la escala necesaria para que los límites de la escena se ajusten a la ventana de visualización
        // const scaleX = windowWidth / bounds.x;
        // const scaleY = windowHeight / bounds.y;
        // const scale = Math.min(scaleX, scaleY); // Usar la escala más pequeña para mantener la relación de aspecto

        // // Calcular la posición necesaria para centrar la escena en la ventana de visualización
        // const offsetX = (windowWidth - bounds.x * scale) / 2 - bounds.x * scale;
        // const offsetY = (windowHeight - bounds.y * scale) / 2 - bounds.y * scale;

        // console.log(scale)
        // // Aplicar la escala y la posición al contenedor principal o a la cámara
        // this.app.stage.scale.set(scale);
        // this.app.stage.position.set(offsetX, offsetY);

        // // get layers
        // const nodesLayer = this.stage.findOne('#layerEntities')
        // const layerSize = nodesLayer.getClientRect({
        //   relativeTo: this.stage,
        // })

        // // calculate sizes
        // const stageSize = this.stage.getSize()
        // const scaleX = stageSize.width / layerSize.width
        // const scaleY = stageSize.height / layerSize.height
        // const scaleValue = Math.min(scaleX, scaleY) * 0.9

        // // move canvas
        // // this.MoveStage(this.stage, { x: 0, y: 20 }, { x: scaleValue, y: scaleValue })
        // this.MoveStage(this.stage, { x: 10, y: 40 }, { x: scaleValue, y: scaleValue })
        //   // .then(r => resolve() )
        this.globalContainer.position.x = 0
        this.globalContainer.position.y = 0
        this.zoomLevel = 1
        this.globalContainer.scale.set(this.zoomLevel, this.zoomLevel);

        resolve()
      })
    }

    Selecting(value, workLayer=null) {
      this.selecting = value
      this.workLayer = workLayer
    }

    Unselect() {
      this.selecting = false
      if (this.workLayer !== 'nodes') {
        this.picsContainer.children.forEach( (el) => {
            if( el.picSelected )
              el.SelectPic(el)
        } );
      } else {
        this.picsContainer.children.filter( (el) => {
          return el.picType === this.workLayer
        } ).forEach(p => {
          if (p.picSelected)
            p.SelectPic(p)
        });
      }
    }

    Dragging(value) {
      this.dragging = value
      this.dragData = null
    }

    MoveStage(stage,  location,  scale) {
      const { x, y } = location
      // const tween = new Konva.Tween({
      //   duration: 0.35,
      //   easing: Konva.Easings.EaseInOut,
      //   node: stage,
      //   onFinish: () => {
      //     fnCallback()
      //     tween.destroy
      //   },
      //   scaleX: (scale && scale.x) || 1,
      //   scaleY: (scale && scale.y) || 1,
      //   x,
      //   y,
      // })

      // tween.play()
      stage.x(x)
      stage.y(y)
      stage.scaleX((scale && scale.x) || 1)
      stage.scaleY((scale && scale.y) || 1)
      stage.batchDraw()
    }

    // Metodo que permite obtener la posicion que debe mostrarse un elemento respetando los limites del
    // contenedor
    GetPosition(discanceXMin, distanceYMin) {
      const mousePos = this.stage.getPointerPosition();

      let x = mousePos.x //- 190;
      let y = mousePos.y //- 40;

      var contenedorWidth = this.stage.width();
      var contenedorHeight = this.stage.height();

      if ( mousePos.x <= discanceXMin ) {
        // console.log('El punto está cerca del límite izquierdo del contenedor.');
      } else {
        // console.log('El punto no está cerca del límite izquierdo del contenedor.');
      }

      if (mousePos.x >= contenedorWidth - discanceXMin) {
        x = mousePos.x - discanceXMin
        // console.log('El punto está cerca del límite derecho del contenedor.');
      } else {
        // console.log('El punto no está cerca del límite derecho del contenedor.');
      }

      if (mousePos.y <= distanceYMin) {
        // console.log('El punto está cerca del límite superior del contenedor.');
      } else {
        // console.log('El punto no está cerca del límite superior del contenedor.');
      }

      if (mousePos.y >= contenedorHeight - distanceYMin) {
        y = mousePos.y - distanceYMin
        // console.log('El punto está cerca del límite inferior del contenedor.');
      } else {
        // console.log('El punto no está cerca del límite inferior del contenedor.');
      }

      return { x, y }
    }

    _OnResize() {
      let containerWidth = this.domContainer.clientWidth;
      let containerHeight = this.domContainer.clientHeight;
      let stageWidth = containerWidth;
      let stageHeight = containerHeight;

      this.app.renderer.resize(stageWidth, stageHeight);
    }

    Resize() {
      this.stage.width(this.domContainer.offsetWidth)
      this.stage.height(this.domContainer.offsetHeight)

      this.stage.scale({
        x: this.domContainer.offsetWidth / this.stage.width(),
        y: this.domContainer.offsetWidth / this.stage.height()
      });

      // Vuelve a dibujar la capa de Konva
      // this.$refs.refLayerText.getNode().draw();
      // this.$refs.layer.getNode().draw();
      this.stage.draw()
      // this.FitViewKonva()
      this.FitToScreen()
    }

    _GetOffset() {
      if (this.bounds === undefined)
        return { x: 0, y: 0 } // si no existe bounds no hay desplazamiento

      return {
        x: Math.abs( this.bounds.minX  - this.origin.x ), // desplazameinto en x
        y: Math.abs( this.bounds.maxY  - this.origin.y ) // desplazamaiento en y
      }
    }

    _OnPointerEvent(e) {
        const canvasRect = e.target.getBoundingClientRect()
        const canvasCoord = {x: e.clientX - canvasRect.left, y: e.clientY - canvasRect.top}
        this._Emit(e.type, {
            domEvent: e,
            canvasCoord,
            position: this._CanvasToSceneCoord(canvasCoord.x, canvasCoord.y)
        })
    }

    // _OnResize(entry) {
    //     this.SetSize(Math.floor(entry.contentRect.width), Math.floor(entry.contentRect.height))
    // }

    _LoadBatch(scene, batch) {

        const objects = new Batch(this, scene, batch).CreateObjects()

        const layer = this.layers.get(batch.key.layerName)

        for (const obj of objects) {
            this.scene.add(obj)
            if (layer) {
                layer.PushObject(obj)
            }
        }
    }

    _GetColorLayer(nameLayer) {
      let layer = this.layers.find((l) => l.name === nameLayer)
      return this._GetCssColor(layer.color)
    }

    _GetCssColor(value) {
        let s = value.toString(16)
        while (s.length < 6) {
            s = "0" + s
        }
        return "#" + s
    }

    /** Ensure the color is contrast enough with current background color.
     * @param color {number} RGB value.
     * @return {number} RGB value to use for rendering.
     */
    _TransformColor(color) {
        if (!this.options.colorCorrection && !this.options.blackWhiteInversion) {
            return color
        }
        /* Just black and white inversion. */
        const bkgLum = Luminance(this.clearColor)
        if (color === 0xffffff && bkgLum >= 0.8) {
            return 0
        }
        if (color === 0 && bkgLum <= 0.2) {
            return 0xffffff
        }
        if (!this.options.colorCorrection) {
            return color
        }
        const fgLum = Luminance(color)
        const MIN_TARGET_RATIO = 1.5
        const contrast = ContrastRatio(color, this.clearColor)
        const diff = contrast >= 1 ? contrast : 1 / contrast
        if (diff < MIN_TARGET_RATIO) {
            let targetLum
            if (bkgLum > 0.5) {
                targetLum = bkgLum / 2
            } else {
                targetLum = bkgLum * 2
            }
            if (targetLum > fgLum) {
                color = Lighten(color, targetLum / fgLum)
            } else {
                color = Darken(color, fgLum / targetLum)
            }
        }
        return color
    }

    _Emit(eventName, data = null) {
      this.canvas.dispatchEvent(new CustomEvent(EVENT_NAME_PREFIX + eventName, { detail: data }))
    }
}

PixiViewer.MessageLevel = MessageLevel

PixiViewer.DefaultOptions = {
    canvasWidth: 400,
    canvasHeight: 300,
    /** Automatically resize canvas when the container is resized. This options utilizes
     *  ResizeObserver API which is still not fully standardized. The specified canvas size is
     *  ignored if the option is enabled.
     */
    autoResize: false,
    /** Frame buffer clear color alpha value. */
    clearAlpha: 1.0,
    /** Use alpha channel in a framebuffer. */
    canvasAlpha: false,
    /** Assume premultiplied alpha in a framebuffer. */
    canvasPremultipliedAlpha: true,
    /** Use antialiasing. May degrade performance on poor hardware. */
    antialias: true,
    /** Correct entities colors to ensure that they are always visible with the current background
     * color.
     */
    colorCorrection: false,
    /** Simpler version of colorCorrection - just invert pure white or black entities if they are
     * invisible on current background color.
     */
    blackWhiteInversion: true,
    /** Size in pixels for rasterized points (dot mark). */
    pointSize: 2,
    /** Retain the simple object representing the parsed DXF - will consume a lot of additional
     * memory.
     */
    retainParsedDxf: false,
    /** Whether to preserve the buffers until manually cleared or overwritten. */
    preserveDrawingBuffer: false,
    /** Encoding to use for decoding DXF file text content. DXF files newer than DXF R2004 (AC1018)
     * use UTF-8 encoding. Older files use some code page which is specified in $DWGCODEPAGE header
     * variable. Currently parser is implemented in such a way that encoding must be specified
     * before the content is parsed so there is no chance to use this variable dynamically. This may
     * be a subject for future changes. The specified value should be suitable for passing as
     * `TextDecoder` constructor `label` parameter.
     */
    fileEncoding: "utf-8"
}

class Layer {
    constructor(name, displayName, color) {
        this.name = name
        this.displayName = displayName
        this.color = color
        this.objects = []
    }

    PushObject(obj) {
        this.objects.push(obj)
    }

    Dispose() {
        for (const obj of this.objects) {
            obj.geometry.dispose()
        }
        this.objects = null
    }
}

// De aquí para abajo es para tratamiento del codigo de color

/** Transform sRGB color component to linear color space. */
function LinearColor(c) {
    return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4)
}

/** Transform linear color component to sRGB color space. */
function SRgbColor(c) {
    return c < 0.003 ? c * 12.92 : Math.pow(c, 1 / 2.4) * 1.055 - 0.055
}

/** Get relative luminance value for a color.
 * https://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef
 * @param color {number} RGB color value.
 * @return {number} Luminance value in range [0; 1].
 */
function Luminance(color) {
    const r = LinearColor(((color & 0xff0000) >>> 16) / 255)
    const g = LinearColor(((color & 0xff00) >>> 8) / 255)
    const b = LinearColor((color & 0xff) / 255)

    return r * 0.2126 + g * 0.7152 + b * 0.0722
}

/**
 * Get contrast ratio for a color pair.
 * https://www.w3.org/TR/2008/REC-WCAG20-20081211/#contrast-ratiodef
 * @param c1
 * @param c2
 * @return {number} Contrast ratio between the colors. Greater than one if the first color color is
 *  brighter than the second one.
 */
function ContrastRatio(c1, c2) {
    return (Luminance(c1) + 0.05) / (Luminance(c2) + 0.05)
}

function HlsToRgb({h, l, s}) {
    let r, g, b
    if (s === 0) {
        /* Achromatic */
        r = g = b = l
    } else {
        function hue2rgb(p, q, t) {
            if (t < 0) {
                t += 1
            }
            if (t > 1) {
                t -= 1
            }
            if (t < 1/6) {
                return p + (q - p) * 6 * t
            }
            if (t < 1/2) {
                return q
            }
            if (t < 2/3) {
                return p + (q - p) * (2/3 - t) * 6
            }
            return p
        }

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s
        const p = 2 * l - q
        r = hue2rgb(p, q, h + 1/3)
        g = hue2rgb(p, q, h)
        b = hue2rgb(p, q, h - 1/3)
    }

    return (Math.min(Math.floor(SRgbColor(r) * 256), 255) << 16) |
           (Math.min(Math.floor(SRgbColor(g) * 256), 255) << 8) |
            Math.min(Math.floor(SRgbColor(b) * 256), 255)
}

function RgbToHls(color) {
    const r = LinearColor(((color & 0xff0000) >>> 16) / 255)
    const g = LinearColor(((color & 0xff00) >>> 8) / 255)
    const b = LinearColor((color & 0xff) / 255)

    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)
    let h, s
    const l = (max + min) / 2

    if (max === min) {
        /* Achromatic */
        h = s = 0
    } else {
        const d = max - min
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
        switch (max) {
        case r:
            h = (g - b) / d + (g < b ? 6 : 0)
            break;
        case g:
            h = (b - r) / d + 2
            break
        case b:
            h = (r - g) / d + 4
            break
        }
        h /= 6
    }

    return {h, l, s}
}

function Lighten(color, factor) {
    const hls = RgbToHls(color)
    hls.l *= factor
    if (hls.l > 1) {
        hls.l = 1
    }
    return HlsToRgb(hls)
}

function Darken(color, factor) {
    const hls = RgbToHls(color)
    hls.l /= factor
    return HlsToRgb(hls)
}
