<template>
    <div class="panel">
        <b-table :data="warehouses" :loading="isLoadingTable" paginated backend-pagination :per-page="perPage"
            :total="total" @page-change="onPageChange" backend-sorting :default-sort="orderField"
            :default-sort-direction="orderDir" @sort="onSort" class="w-full" striped bordered icon-pack="fas">
            <template>
                <b-table-column field="code" :label="$t('code')" sortable v-slot="props">
                    {{ props.row.code }}
                </b-table-column>

                <b-table-column field="name" :label="$t('name')" sortable v-slot="props">
                    {{ props.row.name }}
                </b-table-column>

                <b-table-column field="description" :label="$t('description')" sortable v-slot="props">
                    {{ props.row.description || '-' }}
                </b-table-column>

                <b-table-column field="location" :label="$t('warehouse_location')" sortable v-slot="props">
                    {{ props.row.location }}
                </b-table-column>

                <b-table-column field="created_at" :label="$t('created_at')" sortable v-slot="props" centered>
                    {{ formatDate(props.row.created_at) }}
                </b-table-column>


                <b-table-column :label="$t('actions')" field="actions" centered width="150" v-slot="props">
                    <div class="w-full h-full flex items-center justify-around">
                        <b-tooltip :label="$t('edit_warehouse')" position="is-left">
                            <div @click="editWarehouse(props.row)" class="cursor-pointer flex text-blue">
                                <b-icon pack="fas" icon="pencil-alt" />
                            </div>
                        </b-tooltip>
                        <b-tooltip :label="$t('delete_warehouse')" position="is-left" type="is-danger">
                            <div @click="deleteWarehouse(props.row.id, props.row.name)"
                                class="cursor-pointer flex text-red">
                                <b-icon pack="fas" icon="trash-alt" />
                            </div>
                        </b-tooltip>
                    </div>
                </b-table-column>
            </template>

            <template #empty>
                <div class="has-text-centered">{{ $t('no_results') }}</div>
            </template>
        </b-table>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "WarehouseTable",
    props: {
        projectId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            warehouses: [],
            isLoadingTable: true,
            perPage: 25,
            orderField: 'name',
            orderDir: 'asc',
            page: 1,
            last: 1,
            total: 0,
        }
    },
    created() {
        this.getWarehouses();
    },
    methods: {
        async getWarehouses() {
            this.isLoadingTable = true;

            let filters = {
                order_field: this.orderField,
                order_dir: this.orderDir,
                per_page: this.perPage,
                page: this.page,
            };

            const { data } = await axios.get(`/api/v2/project/${this.projectId}/warehouses`, { params: filters });

            if (data && data.success) {
                this.warehouses = data.warehouses;
                this.last = data.lastPage;
                this.total = data.total;
                this.isLoadingTable = false;
            }
        },

        formatDate(date) {
            return date ? new Date(date).toLocaleString() : '-';
        },

        deleteWarehouse(warehoseId, warehouseName) {
            this.$dialog.confirm({
                title: this.$t('delete'),
                message: this.$t('delete_confirm_text', [warehouseName]),
                confirmText: this.$t('delete') + ' ' + this.$t('storehouse'),
                type: 'is-danger',
                hasIcon: true,
                onConfirm: async () => {
                    let url = '/api/v2/warehouse/' + warehoseId + '/delete'
                    const { data } = await axios.delete(url)
                    if (data && data.success) {
                        this.$notify.success('success_deleting_generic')
                        this.getWarehouses()
                        // this.$parent.$emit('epc-updated-list')
                    } else {
                        this.$notify.error(data.error)
                    }
                }
            })
        },
        editWarehouse(warehouse) {
            this.$emit('epc-edit', warehouse)
        },

        onPageChange(page) {
            this.page = page;
            this.getWarehouses();
        },

        onSort({ field, order }) {
            this.orderField = field;
            this.orderDir = order;
            this.getWarehouses();
        },

        reload(arg = {}) {
            let filters = { ...arg, ...this.filters }
            filters.page = 1
            this.getWarehouses(filters);
        },
    }
}
</script>

<style lang="scss" scoped></style>
