<template>
<div>
  <b-collapse class="card filters-collapse" :open.sync="showFilters">
    <div slot="trigger" class="card-header filters-trigger">
      <p class="card-header-title">
        {{ $t('filters') }}
      </p>
      <a class="card-header-icon">
        <b-icon pack="fa" v-show="! showFilters" icon="plus-circle"/>
        <b-icon pack="fa" v-show="showFilters" icon="minus-circle"/>
      </a>
    </div>
    <div class="panel" style="border-width: 0">
      <div class="flex items-center mb-4">
        <div class="w-1/4 mr-4">
          <b-field :label="$t('search_by_material')">
            <b-input
              v-model="filterProductCode"
              :placeholder="$t('code')"
              type="search"
              icon="search"
              icon-pack="fas"
              @input="checkInputTextFilter(filterProductCode)">
            </b-input>
          </b-field>
        </div>
        <div class="w-1/4 mr-4">
          <b-field :label="$t('name')">
            <b-input
              v-model="filterProductName"
              :placeholder="$t('material')"
              type="search"
              icon="search"
              icon-pack="fas"
              @input="checkInputTextFilter(filterProductName)">
            </b-input>
          </b-field>
        </div>
      </div>

      <button class="button is-primary btn-blue" @click="cleanFiltersClicked()">{{$t('clean_filters')}}</button>
    </div>
  </b-collapse>
  <!-- <span v-if="! showFilters && ! blankFilters" class="mx-2 font-bold cursor-pointer text-red" @click="cleanFiltersClicked()">
    {{$t('clean_filters')}} <b-icon icon="trash-alt" pack="fas" size="is-small" class="text-red"/>
  </span> -->
</div>
</template>

<script>
  import moment from "moment";
  import axios from "axios";
  import {mapGetters} from "vuex";
  import EventBus from '~/plugins/bus'
  import Multiselect from 'vue-multiselect'

  export default {
    name: 'storehouse-filters',

    computed: {
      // ...mapGetters({
      //   project: 'app/project',
      // }),

      // true si todos los filtros estan vacios o con su valor por defecto
      blankFilters() {
        return this.filterProductName == null
          && this.filterProductCode == null
        },
    },

    components: {
      Multiselect
    },

    props: {
      projectId: { type: Number, require:true }
    },

    data: () => ({
      showFilters: false,
      filterProductName: null,
      filterProductCode: null,
      ajaxTimerProducts: null,
    }),

    watch: {},

    mounted() {
      // this.getExtrafields();
    },

    methods: {
      generateFilters() {
        // let params = {p: this.projectId, s_num: 1};
        let params = { };

        //Filtro por codigo
        if (this.filterProductCode) {
          params.filter_code = this.filterProductCode
        }

        //Filtro por producto
        if (this.filterProductName) {
          params.filter_name = this.filterProductName
        }

        return params
      },

      async getExtrafields() {
        const { data } = await axios.get('/api/v2/project/' + this.projectId + '/store-extrafields')
        if (data && data.success) {

          this.extrafields = data.extrafields;
          if( this.extrafields.length )  {
            let field = this.extrafields.find( item => item.name.toLowerCase() === 'propósito' )
            if( field && field.values) {
              this.purposes = field.values
            }
          }
        } else {
          this.$notify.error('error_loading_extra_fields_data')
        }
      },

      applyFilters(page) {
        // let args = this.generateFilters();
        // this.$emit('filters-updated', args);
        this.$emit('filters-updated', true);
      },

      cleanFiltersClicked() {
        this.filterProductName = null;
        this.filterProductCode = null;
        this.page = 1; //TODO no deberíamos paginar a esta altura
        this.isLoadingTable = true;

        this.applyFilters(1); //TODO no deberíamos paginar a esta altura
      },

      checkInputTextFilter(filter) {
        clearTimeout(this.ajaxTimerProducts);

        if (filter.length >= 3 || filter.length == 0) {
          this.ajaxTimerProducts = setTimeout(() => {
            this.applyFilters(1)
          }, 500);
        }
      },

    },

  };
</script>

<style>
  .filters-collapse {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .filters-trigger:hover {
    background-color: #f8fafc;
  }
</style>
