<template>
    <b-modal :active.sync="isModalActive" :width="768" scroll="keep" :can-cancel="canCancel" @close="closeModal">
        <div class="border border-blue-light rounded shadow bg-white p-4">
            <form @submit.prevent="saveWarehouse" class="w-full">
                <header class="font-thin uppercase text-blue-light modal-card-head_ mb-4">
                    {{ isEditMode ? $t("edit_warehouse") : $t("add_warehouse") }}
                </header>

                <div class="modal-card-body_ relative">
                    <div class="border-grey-light border-b border-dashed mb-3">
                        <div class="w-full flex flex-row mb-3 gap-4">
                            <div class="w-1/2">
                                <b-field :label="$t('code')">
                                    <b-input v-model="form.code" required
                                        :placeholder="$t('code') + ' ' + $t('storehouse')" maxlength="50" />
                                </b-field>
                            </div>

                            <div class="w-1/2">
                                <b-field :label="$t('name')">
                                    <b-input v-model="form.name" required
                                        :placeholder="$t('name') + ' ' + $t('storehouse')" />
                                </b-field>
                            </div>
                        </div>

                        <div class="w-full flex flex-row mb-3 gap-4">
                            <div class="w-1/2">
                                <b-field :label="$t('description')">
                                    <b-input v-model="form.description"
                                        :placeholder="$t('description') + ' ' + $t('storehouse')" />
                                </b-field>
                            </div>

                            <div class="w-1/2">
                                <b-field :label="$t('warehouse_location')">
                                    <b-input v-model="form.location" required :placeholder="$t('warehouse_location')"
                                        maxlength="255" />
                                </b-field>
                            </div>
                        </div>

                        <b-loading :is-full-page="false" v-model="processingData" :can-cancel="false"></b-loading>
                    </div>
                </div>

                <footer class="modal-card-foot_">
                    <div class="w-full inline-flex justify-end">
                        <button class="btn btn-blue" nativeType="submit" v-if="!processingData">{{ $t("save")
                            }}</button>
                        <v-button-processing v-if="processingData" />
                    </div>
                </footer>
            </form>
        </div>
    </b-modal>
</template>

<script>
import axios from "axios";

export default {
    name: "WarehouseModal",

    props: {
        projectId: { type: Number, required: true }
    },

    data() {
        return {
            isModalActive: false,
            processingData: false,
            canCancel: ["escape", "x", "outside"],
            isEditMode: false,

            form: {
                id: null,
                code: "",
                name: "",
                description: "",
                location: "",
            }
        };
    },

    methods: {
        show(warehouse = null) {
            if (warehouse && warehouse.id) {
                this.isEditMode = true;
                this.form = { ...warehouse };
            } else {
                this.isEditMode = false;
                this.resetForm();
            }
            this.isModalActive = true;
        },

        hide() {
            this.isModalActive = false;
            this.resetForm();
        },

        resetForm() {
            this.form = {
                id: null,
                code: "",
                name: "",
                description: "",
                location: "",
            };
        },

        async saveWarehouse() {
            this.processingData = true;
            let url = `/api/v2/project/${this.projectId}/warehouse`;
            let method = "post";

            if (this.form.id) {
                url = `/api/v2/warehouse/${this.form.id}`;
                method = "put";
            }

            try {
                await axios({ method, url, data: this.form });

                this.$toast.open({
                    message: this.$t("save_successful"),
                    type: "is-success",
                    position: "is-top-right"
                });

                this.hide();
                this.$emit("reload-list");
                this.$emit("epc-store");
            } catch (error) {
                this.$toast.open({
                    message: error.response?.data?.message || this.$t("save_error"),
                    type: "is-danger",
                    position: "is-top-right"
                });
            } finally {
                this.processingData = false;
            }
        },

        closeModal() {
            this.$emit("update:show", !this.show);
        }
    }
};
</script>

<style scoped></style>