<template>
  <div class="page-with-top-bar">
    <project-admin-quick-buttons hiddenOption="storehouse" />
    <section>
      <div class="mt-4">
        <b-tabs type="is-boxed" v-model="mainActiveTab">
          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="store-alt"></b-icon>
              <span> {{ $t('storehouse') }} </span>
            </template>
            <div>
              <manage-inventory ref="manage_inventories" :projectId="project_id" :products="products"
                :providers="providers" />
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="shopping-cart"></b-icon>
              <span> {{ $t('inputs') }} </span>
            </template>
            <div>
              <manage-purchases ref="manage_purchanses"  :projectId="project_id" :products="products" :providers="providers"
                @epc-updated-list="handleUpdatedList" />
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="shopping-cart" class="fa-flip-horizontal"></b-icon>
              <span> {{ $t('outputs') }} </span>
            </template>
            <div>
              <manage-outs ref="manage_outs" :projectId="project_id" :canImport="false" :products="products" :providers="providers"
                @epc-updated-list="handleUpdatedList" />
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="users"></b-icon>
              <span> 1.- {{ $t('providers') }} </span>
            </template>
            <div>
              <manage-providers :projectId="project_id" @epc-store-providers="handleStoreProviders" />
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="object-ungroup"></b-icon>
              <span> 2.- {{ $t('extrafields') }} </span>
            </template>
            <div>
              <manage-extrafields :projectId="project_id" />
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="cubes"></b-icon>
              <span> 3.- {{ $t('materials') }} </span>
            </template>
            <div>
              <manage-materials :projectId="project_id" @epc-store-products="handleStoreProducts" />
            </div>
          </b-tab-item>
          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="warehouse"></b-icon>
              <span> 4.- {{ $t('storehouse') }} </span>
            </template>
            <div>
              <manage-warehouse :projectId="project_id" @epc-store-warehouse="handleStoreWarehouse" />
            </div>
          </b-tab-item>


          <!-- <b-tab-item>
                    <template slot="header">
                        <b-icon pack="fas" icon="file-import"></b-icon>
                        <span> {{ $t('material') }} </span>
                    </template>
                    <div>
                        <font class="text-xs">Las actividades proporcionadas en el excel y que no sean de este proyecto serán ignoradas.</font>
                        <import-entities
                                :can-overwrite="true"
                                :project-id="project_id"
                                :with-history="false"
                                entity-type="document-folders"
                        >
                          <template v-slot:descripcion>
                            <span class="text-orange-dark">{{$t('activity_import_warning')}}</span>
                          </template>
                        </import-entities>
                    </div>
                </b-tab-item> -->

          <!-- <b-tab-item> algun dia tendra un exportador
                    <template slot="header">
                        <b-icon pack="fas" icon="file-export"></b-icon>
                        <span> {{ $t('export_activities') }} </span>
                    </template>
                    <div>
                        <export-activities :project_id="project_id"></export-activities>
                    </div>
                </b-tab-item> -->
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import importEntities from '../../components/import-entities'
import exportActivities from '../../components/export-activities'
import ProjectAdminQuickButtons from '·/components/project-admin-quick-buttons'
import ManageInventory from '../../components/storehouse/manage-inventory.vue'
import ManageMaterials from '../../components/storehouse/manage-materials.vue'
import ManageProviders from '../../components/storehouse/manage-providers.vue'
import ManageExtrafields from '../../components/storehouse/manage-extrafields.vue'
import ManagePurchases from '../../components/storehouse/manage-purchases.vue'
import ManageOuts from '../../components/storehouse/manage-outs.vue'
import ManageWarehouse from '../../components/storehouse/warehouse/manage-warehouse'

export default {
  components: {
    importEntities,
    exportActivities,
    ProjectAdminQuickButtons,
    ManageInventory,
    ManageMaterials,
    ManageProviders,
    ManageExtrafields,
    ManagePurchases,
    ManageOuts,
    ManageWarehouse
  },
  middleware: "auth",
  title: "storehouse",
  menuOption: "0-2",

  computed: {
    ...mapGetters({
      navData: 'app/navData',
    }),
  },

  data: () => ({
    project_id: null,
    mainActiveTab: 0,

    products: [],
    providers: [],
    activities: [],
    warehouses: [],
  }),

  created() {
    this.$options.subtitle = ['project_actions_page_subtitle', [this.navData.project_name.toUpperCase()]]
    this.project_id = this.navData.project_id
  },

  methods: {
    handleStoreProducts(e) {
      this.products = e;
    },
    handleStoreProviders(e) {
      this.providers = e;
    },

    handleUpdatedList(e) {
      // si componente purchases & outs emiten evento updated list actualizamos la lista del inventario aplicando sus filtros
      this.$refs.manage_inventories.filtersUpdate()
    },
    handleStoreWarehouse() {
      this.$refs.manage_outs.getWarehouses();
      this.$refs.manage_purchanses.getWarehouses();
    } 

  },
};

</script>
