<template>
  <div class="flex-col">
    <b-tabs type="is-boxed" v-model="mainActiveTab">

      <b-tab-item>
        <template slot="header">
          <b-icon pack="fas" icon="tasks"></b-icon>
          <span> {{ project.name }} </span>
        </template>
        <div>

          <!-- <div class="flex justify-end">
            <div class="flex w-7/8">
              <b-tooltip :label="txtTooltipExport || this.$t('export')" position="is-left">
                <div class="rounded-full bg-blue w-8 h-8 flex ml-3 items-center justify-center text-grey-lightest cursor-pointer" @click="exportReport">
                  <b-icon icon="download" pack="fas" size="is-small" v-if="! isLoadingExport" />
                  <b-icon
                    pack="fas"
                    icon="sync-alt"
                    custom-class="fa-spin"
                    v-if="isLoadingExport"
                    >
                  </b-icon>
                </div>
              </b-tooltip>
            </div>
          </div> -->


          <!-- Seccion de Extrafields -->
          <div class="w-full panel">
            <div class="flex">
              <div class="flex flex-wrap w-full">
                <div v-for="extrafield in dataExtrafields" :key="'extrafield_' + extrafield.id" class="flex flex-col w-1/4">
                  <div class="w-full text-black font-bold">{{ extrafield.field.name }}</div>
                  <div class="w-full">{{ extrafield.value_free ? extrafield.value_free : '--' }}</div>
                </div>
                <div v-if="!extrafieldsFound" class="w-full text-center">No extrafields found</div>
              </div>
            </div>
          </div>


          <!-- Panel 2  BARRAS GRUPOS -->
          <div class="w-full mt-4 panel">
            <!-- <div class="flex flex-row w-full text-black font-bold justify-end text-xs"> -->
              <!-- <div>{{ $t('last_update') }}: {{ formatDate() }}</div>  -->
              <!-- Este panel no se cachea, mostrará hora actual -->
            <!-- </div> -->
            <div class="flex flex-row w-full text-blue font-bold">{{ 'Current Progress' }}</div>
            <div class="flex flex-row w-full relative">
              <!--  <div v-if="isLoadingPanels" class="w-full relative text-center"> -->
                <b-loading :is-full-page="false" v-model="isLoadingPanels" :can-cancel="false"></b-loading>
              <!-- </div> -->
              <bar-chart :data="groupsChartsCombined" suffix="%" :min="0" :max="100" :stacked="false" :download="true"  />
            </div>
          </div>


          <!-- Barras Generales de progreso -->
          <div class="w-full mt-8 panel">
            <div style="width: 100%; text-align: center;">
              <div class="center">
                <label class="font-bold" style="font-size: 12px; color: #444">
                  <div style="height:10px; width: 40px; background: #95a3e0; display: inline-block"></div>
                  Total Scheduled: {{ totalScheduled }}%
                </label>
                <label class="font-bold" style="font-size: 12px; color: #444">
                  <div style="height:10px; width: 40px; background: #ea9285; display: inline-block"></div>
                  Total Executed: {{ totalProgress }}%
                </label>
              </div>
              <div v-if="isLoadingPanels" class="">Loading...</div>
              <div style="width: 100%; background-color: #f3f3f3; border: 1px solid #ddd; border-radius: 5px; margin-top: 5px;">
                <div :style="{ 'width': totalScheduled + '%', 'background-color': '#95a3e0', 'height': 15+'px', 'border-radius': 5+'px'}"></div>
              </div>
              <div style="width: 100%; background-color: #f3f3f3; border: 1px solid #ddd; border-radius: 5px; margin-top: 5px;">
                <div :style="{'width': totalProgress + '%', 'background-color': '#ea9285', 'height': 15+'px', 'border-radius': 5+'px'}"></div>
              </div>
            </div>
          </div>


          <!-- Tabla resumen progresos grupos -->
          <div class="w-full mt-8 panel relative">
            <b-table
              icon-pack="fas"
              :data="groupsTable"
              :bordered="true"
              :loading="isLoadingPanels"
              >
              <template>
                <b-table-column field="task" cell-class="w-5/12">
                  <template v-slot:header=""><span class="text-xs">{{ $t('task') }}</span></template>
                  <template v-slot="props">{{ props.row.name }}</template>
                </b-table-column>

                <b-table-column field="schedules_start">
                  <template v-slot:header=""><span class="text-xs">{{ 'Scheduled Start' }}</span></template>
                  <template v-slot="props">{{ formatDateCustom(props.row.current_start_date) }}</template>
                </b-table-column>

                <b-table-column field="schedules_end">
                  <template v-slot:header=""><span class="text-xs">{{ 'Scheduled End' }}</span></template>
                  <template v-slot="props">{{ formatDateCustom(props.row.current_end_date) }}</template>
                </b-table-column>

                <b-table-column field="current_start">
                  <template v-slot:header=""><span class="text-xs">{{ 'Current Start' }}</span></template>
                  <template v-slot="props">{{ formatDateCustom(props.row.real_start_date) }}</template>
                </b-table-column>

                <b-table-column field="current_end">
                  <template v-slot:header=""><span class="text-xs">{{ 'Current End' }}</span></template>
                  <template v-slot="props">{{ formatDateCustom(props.row.real_end_date) }}</template>
                </b-table-column>

                <b-table-column field="avance">
                  <template v-slot:header=""><span class="text-xs">% {{ 'Avance' }}</span></template>
                  <template v-slot="props">{{ props.row.progress_real }}</template>
                </b-table-column>
              </template>

              <template #empty>
                  <div class="has-text-centered">{{ $t('no_results') }}</div>
              </template>
            </b-table>
          </div>

          <div class="panel w-full mt-8">
            <div class="flex flex-row w-full text-blue font-bold">% Progress Project</div>
            <div class="flex flex-row w-full">
              <div v-if="isLoadingProjectProgress" class="w-full relative flex flex-row text-center" style="height: 80px;">
                <b-loading :is-full-page="false" v-model="isLoadingProjectProgress" :can-cancel="false"></b-loading>
              </div>
              <line-chart v-else :data="progressProject" :colors="['#3366CC', '#DC3913', '#129618']" suffix="%" />
            </div>
          </div>

          <div class="panel w-full mt-8">
            <div class="flex flex-row w-full text-blue font-bold">% Progress cost control</div>
            <div class="flex flex-row w-full">
              <line-chart :data="costControlProgress" suffix="€"/>
            </div>
          </div>

          <div class="panel w-full mt-8">
            <div class="flex flex-row w-full text-blue font-bold">{{ 'Venta planificada / Venta real' }}</div>
            <div class="flex flex-row w-full">
              <line-chart :data="ventaPlanificadaReal" suffix="€"/>
            </div>
          </div>

          <div class="panel w-full mt-8">
            <div class="flex flex-row w-full text-blue font-bold">{{ 'Coste planificado / Coste real + previsto' }}</div>
            <div class="flex flex-row w-full">
              <line-chart :data="costPlanificadoReal" suffix="€"/>
            </div>
          </div>


          <!-- Panel 1 Progreso grupos -->
          <!-- <div class="panel w-full mt-8">
            <div class="flex flex-row w-full text-blue font-bold">{{ $t('group_progress') }}</div>
            <div class="flex flex-row w-full">
              <column-chart :data="panel1" suffix="%" :min="0" :max="100"/>
            </div>
          </div> -->


          <!-- Gráficas dinámicas por grupo -->
          <div class="w-full mt-8 panel" v-for="group in groupChartIndividual" :key="'charset_'+group.id">
            <!-- Antiguo Panel 3 -->
            <div class="w-full flex flex-col">
              <div class="flex flex-row w-full text-blue font-bold">{{ $t('progress_of') }} {{group.name}}</div>
              <div v-if="!group.loadDataset" class="flex flex-row w-full">
                <line-chart :suffix="'%'" :min="0" :max="100" :data="group.dataset" />
              </div>
              <div v-else class="w-full relative flex flex-row text-center" style="height: 80px;">
                <b-loading :is-full-page="false" v-model="group.loadDataset" :can-cancel="false"></b-loading>
                <!-- <scale-loader></scale-loader> -->
              </div>
            </div>
          </div>
        </div>
      </b-tab-item>

    </b-tabs>
  </div>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
  import VueChartkick from 'vue-chartkick'
  import Chart from 'chart.js'
  import moment from 'moment'
  import excelByActivityGroups from '·/components/reports/excel-by-activity-groups.vue'

  // import the component
  import Treeselect from '@riophae/vue-treeselect'
  // import the styles
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'

  Vue.use(VueChartkick, {adapter: Chart})
  Vue.use(moment)

  export default {
    middleware: 'auth',
    title: 'project_home_page_title',
    subtitle: 'project_home_page_subtitle',
    menuOption: '2-1',

    components: {
      ScaleLoader,
      Treeselect,
      excelByActivityGroups,
    },

    data: () => ({
      filters: {
        entity_id: 0,
        location_id: 0,
        status_id: 1,
        tag_name: '',
        parent_group_id: null
      },
      isLoading: true,
      panel1: {
        info: [],
        users: []
      },
      groupsTable: [],
      groupChartIndividual: [],
      mainActiveTab: 0,
      options: [],

      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
          isDisabled: node.subgroups_num == 0,
          children: node.children,
        }
      },
      isLoadingExport: false,
      txtTooltipExport: null,

      extrafieldsFound: true,

      dataExtrafields: [],
      totalScheduled: 0,
      totalProgress: 0,
      groupsChartsCombined: [],
      costControlProgress: [],
      ventaPlanificadaReal: [],
      costPlanificadoReal: [],
      progressProject: [],

      isLoadingPanels: true,
      isLoadingProjectProgress: true,
    }),

    computed: {
      ...mapGetters({
        project: 'app/project',
        user: 'auth/user',
      }),
    },

    mounted() {
      this.getDataExtrafields()

      if (this.project == null) {

        this.$router.push('/projects');

      } else {
        // this.getActivityGroupsList() // para que es? lo quito

        this.getPanels();
        // this.getPanel3(); // obsoleto, borrar
        // this.getDataProgressGroups(); // redundante, oculto por ahora
        this.getPanelCost();
        this.getProgressProject();

        let pageInfo = {
          title: this.project.name,
          subtitle: this.$t('project_home_page_subtitle'),
          menuOption: '2-1',
        }

        this.$store.dispatch('app/updatePageInfo', {info: pageInfo})
      }
    },

    methods: {

      // antiguo. antes habia un filtro para ver datos por grupo
      // handleSelectGroup(value, instanceId) {
      //  this.filters.parent_group_id = value ?? null

      //  this.getPanels()
      //  this.panel3 = []
      //  this.getPanel3()
      // },

      // lo quito, no veo que se use
      // async getActivityGroupsList(args = []) {

      //   const {data} = await axios.get(`/api/v2/project/${this.project.id}/activity/groups`, {params: args});

      //   if (data && data.groups) {
      //     this.options = data.groups;
      //   } else {
      //     this.$notify.error('error_loading_activities')
      //   }
      // },

      async getDataExtrafields() {
        let self = this
        let url = '/api/v2/project/' + this.project.id + '/dashboard/wlextrafields';
        await axios.get(url, {params: this.filters})
          .then(response => {
            self.dataExtrafields = response.data.data
            self.extrafieldsFound = self.dataExtrafields.length > 0
          })
          .catch((error) => {
            self.$notify.error(error.error_msg || 'error_retrieving_data')
          });
      },

      // ocultado por ahora, no parece relevante
      // async getDataProgressGroups() {
      //   let self = this
      //   let url = '/api/v2/project/' + this.project.id + '/dashboard/wlgroupprogress';
      //   await axios.get(url, {params: this.filters})
      //     .then(response => {
      //       self.panel1 = response.data.data
      //     })
      //     .catch((error) => {
      //       self.$notify.error(error.error_msg || 'error_retrieving_data')
      //     });
      // },

      async getPanels() {
        let self = this
        self.isLoadingPanels = true;
        let url = '/api/v2/project/' + this.project.id + '/dashboard/wlpanels';

        await axios.get(url, {params: this.filters})
          .then(response => {
            self.groupsChartsCombined = response.data.data.groupsChartsCombined
            self.totalScheduled = response.data.data.totalScheduled
            self.totalProgress = response.data.data.totalProgress
            self.groupsTable = response.data.data.groupsTable
            self.groupChartIndividual = response.data.data.groupChartIndividual
            self.isLoadingPanels = false

            // PARA TESTEAR
            // self.groupChartIndividual = [{ id: 5385, name: "CONSTRUCTION", dataset:	[], loadDataset: true,  }]

            self.loadDatasetGroup()
          })
          .catch((error) => {
            self.$notify.error(error.error_msg || 'error_retrieving_data')
          });
      },

      // async getPanel3() {
      //   let self = this
      //   let url = '/api/v2/project/' + this.project.id + '/dashboard/photovoltaicpanel3';
      //   await axios.get(url, {params: this.filters})
      //     .then(response => {
      //       self.panel3 = response.data.data
      //     })
      //     .catch((error) => {
      //       self.$notify.error(error.error_msg || 'error_retrieving_data')
      //     });
      // },

      loadDatasetGroup() {
        // filtramos los que faltar por cargar (loadDataset == true)
        // let groups = this.groupChartIndividual.filter(group => group.loadDataset == true)
        let groups = this.groupChartIndividual.filter(group => group.loadDataset == true)
        if (groups.length > 0) {
          // de todos esos nos quedamos solo con el primero para ir llamando de 1 en 1
          this.filters.entity_id = groups[0].id
          this.getDatasetGroup(this.filters.entity_id)
        }
      },

      async getDatasetGroup(groupId) {
        let self = this
        let url = '/api/v2/project/' + this.project.id + '/dashboard/wlgrouppanel';
        await axios.get(url, {params: this.filters})
          .then(response => {
            let data = response.data.data
            let g = self.groupChartIndividual.find(group => group.id == data.id )
            g.loadDataset = false
            g.dataset = data.data

            // si y solo si todo fue bien, llamamos al siguiente grupo
            setTimeout(() => {
              self.loadDatasetGroup()
            }, 500);

          })
          .catch((error) => {
            self.$notify.error(error.error_msg || 'error_retrieving_data')
            let g = self.groupChartIndividual.find(group => group.id == groupId )
            g.loadDataset = false // quitamos el loading y mostramos 'Error loading'
            g.dataset = [{'name': 'Error loading', 'data': []}]
          })
          .finally(() => {
            // quito el setTimeout de aqui porque daba llamadas infinitas si algo fallaba
          });
      },

      async getProgressProject() {
        let self = this
        let url = '/api/v2/project/' + this.project.id + '/dashboard/wlprojectprogress';
        await axios.get(url, {params: this.filters})
          .then(response => {
            self.progressProject = response.data.data
          })
          .catch((error) => {
            self.$notify.error(error.error_msg || 'error_retrieving_data')
          });
        self.isLoadingProjectProgress = false;
      },

      async getPanelCost() {
        let self = this
        // let url = '/api/v2/project/' + this.project.id + '/dashboard/photovoltaicpanel3';
        // await axios.get(url, {params: this.filters})
        //   .then(response => {
        //     self.isLoading = false;
        //     self.panel3 = response.data.data
        //   })
        //   .catch((error) => {
        //     self.$notify.error(error.error_msg || 'error_retrieving_data')
        //   });

        self.costControlProgress = [
          { 'name': "Venta", data: {
            "Enero": 0,
            "Febrero": 0,
            "Marzo": 0,
            "Abril": 0,
            "Mayo": 0,
            "Junio": 0,
            "Julio": 0,
            "Agosto": 0,
            "Septiembre": 0,
            "Octubre": 0,
            "Noviembre": 0,
            "Diciembre": 0,
          } },
          { name: "Coste Planeado", data: {
            "Enero": 0,
            "Febrero": 0,
            "Marzo": 0,
            "Abril": 0,
            "Mayo": 0,
            "Junio": 0,
            "Julio": 0,
            "Agosto": 0,
            "Septiembre": 0,
            "Octubre": 0,
            "Noviembre": 0,
            "Diciembre": 0,
          }},
          { name: "Coste Real", data: {
            "Enero": 0,
            "Febrero": 0,
            "Marzo": 0,
            "Abril": 0,
            "Mayo": 0,
            "Junio": 0,
            "Julio": 0,
            "Agosto": 0,
            "Septiembre": 0,
            "Octubre": 0,
            "Noviembre": 0,
            "Diciembre": 0,
          }},
        ]

        self.ventaPlanificadaReal = [
          { name: "Venta Planificada", data: {
          "Enero": 0,
          "Febrero": 0,
          "Marzo": 0,
          "Abril": 0,
          "Mayo": 0,
          "Junio": 0,
          "Julio": 0,
          "Agosto": 0,
          "Septiembre": 0,
          "Octubre": 0,
          "Noviembre": 0,
          "Diciembre": 0,
        }},
        { name: "Venta Real", data: {
          "Enero": 0,
          "Febrero": 0,
          "Marzo": 0,
          "Abril": 0,
          "Mayo": 0,
          "Junio": 0,
          "Julio": 0,
          "Agosto": 0,
          "Septiembre": 0,
          "Octubre": 0,
          "Noviembre": 0,
          "Diciembre": 0,
        }},
        ],

        self.costPlanificadoReal = [
          { name: "Coste Planificado", data: {
          "Enero": 0,
          "Febrero": 0,
          "Marzo": 0,
          "Abril": 0,
          "Mayo": 0,
          "Junio": 0,
          "Julio": 0,
          "Agosto": 0,
          "Septiembre": 0,
          "Octubre": 0,
          "Noviembre": 0,
          "Diciembre": 0,
        }},
        { name: "Coste Real + previsto", data: {
          "Enero": 0,
          "Febrero": 0,
          "Marzo": 0,
          "Abril": 0,
          "Mayo": 0,
          "Junio": 0,
          "Julio": 0,
          "Agosto": 0,
          "Septiembre": 0,
          "Octubre": 0,
          "Noviembre": 0,
          "Diciembre": 0,
        }},
        ]
      },

      formatDate(date) {
        return moment(date).format('HH:mm (DD-MM)'); //'DD-MM-YYYY HH:mm'
      },

      async exportReport() {

        if( !this.isLoadingExport ){
          this.txtTooltipExport = this.$t('generating');

          this.$notify.success('generating_report');
          this.isLoadingExport = true;
          let self = this
          let url = '/api/v2/project/' + this.project.id + '/dashboard/exportphotovoltaic';
          await axios.get(url, {params: this.filters})
            .then(response => {
              window.open(response.data.data);

              self.$notify.success('report_generated')
              self.isLoadingExport = false;
              self.txtTooltipExport = this.$t('export')
            })
            .catch((error) => {
              //Se produjo un error
              console.log("Error loading exportación")
              self.$notify.error(error.error_msg || 'error_retrieving_data')
              self.isLoadingExport = false
              self.txtTooltipExport = this.$t('export')
            });
        }
      },

      formatDateCustom(dateString) {
        if (!dateString) {
          return null;
        }
        // Split the input string by "-"
        const [year, month, day] = dateString.split("-");
        // Return the date in DD-MM-YYYY format
        return `${day}-${month}-${year}`;
      }
    },
  };
  </script>
