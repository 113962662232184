<template>
    <div>
        <!-- Table assignment by breakdown -->
        <div class="panel">
            <b-table :data="budgets"
              :loading="isLoadingTable" paginated backend-pagination :per-page="perPage"
              :total="total" @page-change="onPageChange" @sort="onFrontSort" class="w-full" striped bordered
              icon-pack="fas" scrollable>
              <template>
                  <b-table-column field="assignment_code" :label="'Mes'" sortable v-slot="props" width="20%">
                      {{ formatDate(props.row.work_date) }}
                  </b-table-column>
                  <b-table-column field="importe_vt" :label="$t('economic_programming')" sortable v-slot="props">
                      {{ formatNumber(props.row.quantity) }}
                  </b-table-column>
              </template>

              <template #empty>
                  <div class="has-text-centered">{{ $t('no_results') }}</div>
              </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    props: {
        projectId: { type: Number, require: true },
    },
    computed: {
        filters() {
            let filters = {
                // 'order_field': this.orderField,
                // 'order_dir': this.orderDir,
                // 'perPage': this.perPage,
                // 'page': this.page,
                'project_id': this.projectId,
                'work_type': 9
            }
            return filters
        },
    },
    data: function () {
        return {
            isLoadingTable: true,
            budgets: [],
            perPage: 25,
            orderField: 'assignment_code',
            orderDir: 'asc',
            page: 1,
            last: 1,
            total: 0,
            applyFilters: {},
            showDetailIcon: true,
        }
    },
    created() {
        this.getAssignmentBreakdown()
    },
    methods: {
        async getAssignmentBreakdown(filters = null) {
            this.isLoadingTable = true
            this.budgets = []
            const params = filters ? filters : this.filters;
            const { data } = await axios.get(`/api/v2/economic-programming`, { params });
            if (data && data.success) {
                this.budgets = data.data;
                // this.last = data.pagination.lastPage;
                // this.total = data.pagination.total;
                this.isLoadingTable = false
            }
        },
         sortTableData(data) {

            if (!data || !data.length) return [];
            return [...data].sort((a, b) => {
                const fieldA = a[this.orderField];
                const fieldB = b[this.orderField];

                // Si no hay valores en el campo, prioriza los no vacíos
                if (fieldA == null) return 1;
                if (fieldB == null) return -1;

                let modifier = this.orderDir === 'asc' ? 1 : -1;
                return fieldA > fieldB ? modifier : fieldA < fieldB ? -modifier : 0;
            });
        },

        onSort(field, order) {
            this.companyId = this.company.id
            this.orderField = field
            this.orderDir = order
            this.getBudgets();
        },
        onFrontSort(field, order) {
            this.orderField = field;
            this.orderDir = order === 'asc' ? 'asc' : 'desc';
        },

        onPageChange(page) {
            this.page = page
            this.getBudgets();
        },

        reload(arg = {}) {
            let filters = { ...arg, ...this.filters }
            this.getAssignmentBreakdown(filters);
        },

        async editButtonClicked(entity) {
            this.$emit('epc-edit', entity)
        },

        async removeResources(assignmentBudget) {
            try {
                this.$dialog.confirm({
                    title: this.$t('delete_breakdown'),
                    message: this.$t('delete_activity_confirm_text', [assignmentBudget.name]),
                    confirmText: this.$t('delete') + ' ' + this.$t('breakdown'),
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: async () => {
                        this.processingData = true;
                        await axios.delete(`/api/v2/assignment-breakdown/${assignmentBudget.id}`);
                        this.$buefy.toast.open({
                            message: this.$t("removed_successfully"),
                            type: 'is-success'
                        });
                        this.$emit('reload-list');
                    },
                    onCancel: () => {
                        this.processingData = false;
                    }
                });
            } catch (error) {
                this.$buefy.toast.open({
                    message: this.$t("remove_error") + (error.response.data.message),
                    type: 'is-danger'
                });
            } finally {
                this.processingData = false;
            }
        },

        archiveClass(value) {
            return value ? "cursor-pointer flex text-yellow-dark" : "cursor-pointer flex text-blue"
        },

        handleSelect(newRow) {
            this.$emit('update:template', newRow);
        },

        clearSelected() {
            this.rowSelected = null
        },
        formatNumber(value) {
            const number = parseFloat(value);
            if (isNaN(number)) return '0,00';
            let [integerPart, decimalPart] = number.toFixed(2).split('.');
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            return `${integerPart},${decimalPart}`;
        },
        formatDate(value) {
          const [year, month, day] = value.split("-"); // Divide la cadena en partes
          return `${day}-${month}-${year}`;
        },
        addButtonBreakdown(assignment) {
            this.$emit('add-by-assignment', assignment);
        },

    },
}
</script>
<style scoped>
.custom-class {
    background-color: red !important;
}
</style>
