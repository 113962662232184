  <template>
  <div>
    <div class="top-bar flex justify-end">
      <v-button v-if="canImport"
        @click="() => { showFormImportEntities = true }"
        icon="file-import"
        class="mr-4"
        :disabled="showFormData"
      >{{ $t('import_outs') }}</v-button>

      <v-button
        @click="openOutsModal(null)"
        icon="shopping-bag"
        :disabled="showFormData"
      >{{ $t('add_out') }}</v-button>

      <out-edit
        ref="out_modal"
        :projectId="projectId"
        :products="products"
        :providers="providers"
        :nameEntity="'activity'"
        @reload-list="handleSuccess"
      />
    </div>

    <div>
      <filters ref="filtersRef"
        :projectId="projectId"
        :products="products"
        :providers="providers"
        :visibleFilters="filtersVisible"
        @filters-updated="filtersUpdated"
      />
    </div>

    <div class="flex">
      <div :class="['w-full', 'mr-2']">
        <outs ref="outs_list"
          :projectId="projectId"
          @epc-edit="handleEditEntity"
        />
      </div>

      <transition name="fade">
        <div class="w-5/12 ml-2" v-if="showFormImportEntities">
          <outs-import
            :projectId="projectId"
            :entity-type="'store-outs'"
            @epc-close="(event) => { showFormImportEntities = false }"
            @epc-success-import="() => { showFormImportEntities = false ; $refs.outs_list.reload() }"
          />
        </div>

      </transition>
    </div>
  </div>
</template>

<script>
import Outs from './outs.vue';
import OutEdit from './out-modal.vue';
import OutsImport from './outs-import.vue';
import Filters from './storehouse-filters.vue'
import axios from 'axios';

export default {
  name: "manage-invnetory",

  components: {
    Outs,
    OutEdit,
    OutsImport,
    Filters,
  },

  data: () => ({
    material: null, // objeto que se crea
    showFormData: false,
    showFormImportEntities: false,
    showFormLink: false,
    warehouses:[],
    filtersVisible: {
      visibleFilterPurposes: false,
      visibleFilterDeliveryNote: true,
      visibleFilterTypeProduct: false,
      visibleFilterMainDiameter: false,
      visibleFilterStockMin: false,
    }
  }),

  props: {
    projectId: { type: Number, required: true },
    canImport: { type: Boolean, default: true },
    products: { type: Array, default: () => ([]) },
    providers: { type: Array, default: () => ([]) },
  },

  created() {
    this.getWarehouses();
  },

  mounted() {
  },

  methods: {

    // handleOnCloseEdit(event) {
    //   this.showFormData = !event
    // },

    handleSuccess() { // recibe la entidad editada
      this.$refs.outs_list.reload()

      this.$emit('epc-updated-list')
    },

    handleEditEntity(entity) {
      this.openOutsModal(entity)
    },

    // Botones de acción
    openOutsModal(entity) {
      let storeout = {
        id: 0,
        product_id: null,
        provider_id: null,
        entity_id: null,
        date_out: null, //new Date(),
        qty: 0,
        price: 0.00,
        delivery_note: "",
      };

      if(entity != null)
      {
        storeout.id = entity.id
        storeout.product_id = entity.inventory.product_id
        storeout.provider_id = entity.inventory.provider_id
        storeout.entity_id = entity.storeable_id
        storeout.date_out = entity.date_out
        storeout.qty = entity.qty
        storeout.price = entity.price
        storeout.delivery_note = entity.delivery_note
      }

      this.$refs.out_modal.show(storeout,this.warehouses);
    },

    filtersUpdated() {
      this.applyFilters(1);
    },

    applyFilters(page) {
      let generatedFilters = this.customizeFilters();
      generatedFilters.page = 1;

      this.$refs.outs_list.reload(generatedFilters)
    },

    customizeFilters() {
      let filters = this.$refs.filtersRef.generateFilters();
      return filters;
    },
    async getWarehouses() {
            try {
                const { data } = await axios.get(`/api/v2/project/${this.projectId}/warehouses`);
                if (data && data.success) {
                    this.warehouses = data.warehouses;
                }
            } catch (error) {
                throw ('Error fetching warehouse:', error);
            }
        },
  }

};
</script>

<style scoped>

</style>
