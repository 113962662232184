<template>
    <div>
        <div class="panel p-2">
            <div class="flex items-center justify-between w-full mb-5">
                <div><label><b>{{ $t('import_warehouses') }}</b></label></div>
                <div>
                    <b-tooltip :label="$t('close')" position="is-left">
                        <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-red text-grey-lightest"
                            @click="onCloseClicked">
                            <b-icon pack="fas" icon="times" size="is-small" />
                        </div>
                    </b-tooltip>
                </div>
            </div>

            <div class="w-full pb-3">
                <import-entities :can-overwrite="true" :project-id="projectId" :withHistory="false"
                    :entity-type="entityType" @success="$emit('epc-success-import')" />
            </div>
        </div>
    </div>
</template>

<script>
import importEntities from '../../../components/import-entities'

export default {
    name: "warehouses-import",
    components: {
        importEntities
    },
    props: {
        projectId: { type: Number, required: true },
        entityType: { type: String, default: 'warehouses' },
    },
    methods: {
        onCloseClicked() {
            this.$emit('epc-close', true)
        },
    },
}
</script>

<style lang="scss" scoped></style>