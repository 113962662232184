<template>
<div class="w-full flex flex-col lg:flex-row">
  <div class="w-full lg:hidden containt-tabs-menu">
    <b-tabs ref="refTabsMenu"
      type="is-toggle-rounded"
      v-model="tabSelectedMenu"
      expanded
      class="sticky top-0 mb-3"
      @input="_HandleChangeTab">

      <b-tab-item label="Dasboard"
        value="tab_menu_dashboard"
        icon="chart-line"
        icon-pack="fas" class="hidden"></b-tab-item>

      <b-tab-item
        label="Comentarios"
        value="tab_menu_comments"
        icon="comment-alt"
        icon-pack="fas"></b-tab-item>

    </b-tabs>
  </div>

  <div class="w-full flex flex-row">
    <!-- Columna izquierda -->
    <div class="w-full lg:w-3/5 lg:mr-2" v-show="showPanel == 'left' || showPanel == 'both'">
      <div class="w-full column-scroll">

        <!-- Titulo resumen -->
        <div class="panel">
          <div class="w-full uppercase text-blue-light font-thin text-lg">
            Información del proyecto
          </div>
          <span class="font-semibold">{{ $t('start_date_label') }}:</span> {{ projectData.current_start_date}}<br>
          <span class="font-semibold">{{ $t('end_date_label') }}:</span> {{ projectData.current_end_date}} <br>
          <span class="font-semibold">{{ $t('expected_end_date') }}:</span> {{ projectData.expected_end_date}} ({{delayedDaysTotal}} {{$t('days')}}) <br>
        </div>

        <!-- Presupuesto / avance -->
        <div class="flex w-full">
          <div class="panel w-1/2">
            <div class="w-full uppercase text-blue-light font-thin text-lg">
              Presupuesto
            </div>
            <!-- pendiente indicar presupuesto total -->
            <span class="font-semibold">Presupuesto:</span> {{ toCurrency(projectData.budget) }} {{ projectData.currency }}<br>
            <span class="font-semibold">Programado:</span> {{ formatCurrency(projectNumbers.costs.estimated, projectNumbers.costs.suffix) }} {{ projectData.currency }}<br>
            <span class="font-semibold">Ejecutado:</span> {{ formatCurrency(projectNumbers.costs.real, projectNumbers.costs.suffix) }} {{ projectData.currency }}<br>
            <span class="font-semibold">Exceso:</span>
            <span :class="{'text-red': excess > 0, 'text-green': excess <= 0}">{{ formatCurrency(excess.toFixed(2), projectNumbers.costs.suffix) }} {{ projectData.currency }}</span>
          </div>

          <div class="panel w-1/2 ml-2">
            <div class="w-full uppercase text-blue-light font-thin text-lg">
              Avance
            </div>
            <span class="font-semibold">Programado:</span> {{ projectNumbers.progress.estimated }}%<br>
            <span class="font-semibold">Ejecutado:</span> {{ projectNumbers.progress.real }}%<br>
            <span class="font-semibold">Actividades con retraso:</span>
            <span :class="{'text-red': dangerCounter, 'text-green': !dangerCounter}">{{ dangerCounter }}
              <span class="cursor-pointer" v-if="dangerCounter" @click="() => { showActivitiesDanger = !showActivitiesDanger }">
                <b-icon :icon="showActivitiesDanger ? 'eye-slash': 'eye'" pack="fas" size="is-small" type="is-info"  />
              </span>
            </span>
          </div>
        </div>

        <!-- Actividades con retraso -->
        <div class="panel" v-if="showActivitiesDanger">
          <div class="w-full uppercase text-blue-light font-thin text-lg">
            <b>{{ $t('activities') + ' CON RETRASO' }}</b>
          </div>

          <b-table
            ref="activitiesdanger"
            :data="activitiesDanger"
            default-sort="notifications"
            default-sort-direction="desc"
            bordered
            class="border-blue-lighter bg-grey-lighter text-xs subactivities"
            narrowed
            :sticky-header="false"
            :mobile-cards="false"
            icon-pack="fas">

            <template>
              <b-table-column field="planning_code" sortable cell-class="vertical-center">
                <template v-slot:header="">
                  {{ $t('code') }}
                </template>
                <template v-slot="activity_info">
                  <div>{{ activity_info.row.planning_code }}</div>
                </template>
              </b-table-column>

              <b-table-column field="name" cell-class="vertical-center" sortable>
                <template v-slot:header="">
                  {{ $t('name') }}
                </template>
                <template v-slot="activity_info">
                  <div :class="activity_info.row.access ? '' : 'cursor-pointer'"
                      @click="onRowClicked(activity_info.row.is_subactivity, activity_info.row.parent_id, activity_info.row.id, activity_info.row.access)">
                    {{ activity_info.row.name }}
                  </div>
                </template>
              </b-table-column>
            </template>
          </b-table>
        </div>

        <!-- Incidencias del proyecto -->
        <div class="panel">
          <div class="w-full uppercase text-blue-light font-thin text-lg">
            {{ $t('issues') }}
          </div>
          <issues-table :is-light-version="true"></issues-table>
        </div>

        <!-- <div v-if="project.can_admin || project.can_participate" class="panel">
            <project-charts
              :project="project"
              :title="$t('progress_label')"
              chartKey="progress"
              :chartType="'line'"
              :isLoading="true"
              @projectChartCalculationDone="(dataset) => getCurrentMonth(dataset)" />
        </div> -->


        <!-- <div v-if="project.can_admin || project.can_participate" class="panel">
            <project-charts
              :project="project"
              :title="$t('earned_value')"
              chartKey="costs"
              :chartType="'column'"
              :isLoading="true"
              @projectChartCalculationDone="(dataset) => getCurrentMonth(dataset)" />
        </div> -->

        <div v-if="project.can_admin || project.can_participate" class="panel">
            <project-charts
              :project="project"
              :title="$t('planned_executed')"
              chartKey="costs2"
              :isLoading="true"
              @projectChartCalculationDone="(dataset) => getCurrentMonth(dataset)" />
        </div>


        <div v-if="project.can_admin || project.can_participate" class="panel">
            <project-economic-preview />
        </div>

      </div>
    </div>

    <!-- Columna derecha -->
    <div class="w-full lg:w-2/5 lg:ml-2" v-show="showPanel == 'right' || showPanel == 'both'">
      <div v-if="project.issue_template? project.can_admin || project.can_participate || project.can_view || project.can_issue_admin || project.can_issue_resolutor
        : project.can_admin || project.can_participate || project.can_view" 
      class="w-full column-scroll">
        <!-- Actividad -->
        <project-notifications ref="project_notifications" />
      </div>
      <div v-else class="w-full column-scroll">
        <div class="panel">
          <span>{{ $t('empty_project_notifications') }}</span>
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { mapGetters } from 'vuex'
import projectNotifications from '·/components/project-notifications'
import projectEconomicPreview from '·/components/project-economic-preview'
import projectCharts from '·/components/project-charts'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import moment from 'moment'
import IssuesTable from '·/pages/project/issues'

Vue.use(moment)

export default {
  middleware: 'auth',
  title: 'project_home_page_title',
  subtitle: 'project_home_page_subtitle',
  menuOption: '2-1',

  components: {
    projectNotifications,
    ScaleLoader,
    projectEconomicPreview,
    projectCharts,
    IssuesTable
  },

  data: () => ({
    projectData: {}, // los datos del proyecto que no estan en la store
    activitiesList: null,
    okCounter: 0,
    warningCounter: 0,
    dangerCounter: 0,
    activitiesDanger: [],
    showActivitiesDanger: false,
    delayedDaysTotal: 0, // total dias acumulados de retraso en las actividades

    // Numeros del proyecto. Al mes actual, progreso y costes estimados y reales.
    // los tomamos de las graficas para no duplicar calculo
    projectNumbers: {
      'progress': {
        'estimated': 0,
        'real': 0,
        'suffix': '%',
      },
      'costs': {
        'estimated': 0,
        'real': 0,
        'suffix': '',
      }
    },

    showPanelRight: true,
    showPanelLeft: true,
    tabSelectedMenu: -1 // tab seleccionado por defecto
  }),

  computed: {
    ...mapGetters({
      user: 'auth/user',
      project: 'app/project',
      mobile: 'device/mobile'
    }),

    excess() {
      return parseFloat(this.projectNumbers.costs.real) -
        parseFloat(this.projectNumbers.costs.estimated)
    },

    showPanel: function() {
      if( this.mobile.on_mobile ) {

        if( this.showPanelLeft && this.showPanelRight ) {
          this.showPanelRight = false
          this.tabSelectedMenu = 0
        }

        return this.showPanelRight ? 'right' : 'left'
      }

      this.showPanelRight = true
      this.showPanelLeft = true
      return 'both'
    }
  },
  mounted() {

    if (this.project == null) {

      this.$router.push('/projects');

    } else {

      this.getProjectData()
      this.getActivitiesList()

      let pageInfo = {
        title: this.project.name,
        subtitle: this.$t('project_home_page_subtitle'),
        menuOption: '2-1',
      }

      this.$store.dispatch('app/updatePageInfo', {info: pageInfo})
    }
  },

  methods: {

    // con los datos de la store no nos vale
    getProjectData() {
      axios.get('/api/v2/project/' + this.project.id)
        .then(response => {
          if (response.data) {
            this.projectData = response.data;
            this.projectData.current_start_date = moment(this.projectData.current_start_date).format('DD/MM/YYYY')
            this.projectData.current_end_date = moment(this.projectData.current_end_date).format('DD/MM/YYYY')
            this.projectData.expected_end_date = null // virtual field
          } else {
            this.$notify.error("error_retrieving_data");
          }
        })
    },

    async getActivitiesList() {
      const { data } = await axios.get('/api/v2/activities?p=' + this.project.id+'&pg=1&s=1') // sin paginacion y con subactividades para fechas fin proyecto
      if (data && data.success) {
        this.activitiesList = data.activities
        this.calculateProjectExpectedEndDate()
        this.calculateActivitiesCounters()
      }
    },


    // calcula el retraso del proyecto en funcion del retraso en comenzar las sub/actividades
    calculateProjectExpectedEndDate() {
        let totalActivities = this.activitiesList.length
        this.activitiesList.forEach((activity) => {

          // si no hay fecha esperada fin, es porque no hay retraso
          let delayedDaysActivity = activity.expected_end_date
            ? moment.duration(moment(activity.expected_end_date, "DD/MM/YYYY").diff(moment(activity.real_end_date, "DD/MM/YYYY"))).asDays()
            : 0;

          // ahora subactividades
          activity.subactivities.forEach((subactivity) => {
            delayedDaysActivity += subactivity.expected_end_date
              ? moment.duration(moment(subactivity.expected_end_date, "DD/MM/YYYY").diff(moment(subactivity.real_end_date, "DD/MM/YYYY"))).asDays()
              : 0;
          })

          this.delayedDaysTotal += delayedDaysActivity
          totalActivities += activity.subactivities.length
        })

        this.delayedDaysTotal = Math.round(this.delayedDaysTotal/totalActivities)
        let projectExpectedEndDate = moment(this.projectData.real_end_date).add(this.delayedDaysTotal, 'days').format('DD/MM/YYYY')
        this.projectData.expected_end_date = projectExpectedEndDate;
    },

    calculateActivitiesCounters() {

      this.activitiesList.forEach((activity) => {

        let diff = this.diffDate(activity.current_end_date)
        if (diff > 3) {
          this.okCounter++
        } else if (diff <=3 && diff > 0) {
          this.warningCounter++
        } else {
          this.dangerCounter++
          // aqui añadir una variable para guardar las actividades fuera de plazo
          this.activitiesDanger.push(activity);
        }

      })

    },

    diffDate(date) {
      let end = moment(date, "DD/MM/YYYY")
      let now = moment()
      let duration = moment.duration(end.diff(now))
      return duration.asDays()
    },

    // reloadNotifications(filters) {
    //   this.$refs.project_notifications.updateFilters(filters)
    // },

    // del dataset de toda la grafica (de costes y progreso), extrae el dato del mes actual para panel resumen
    getCurrentMonth(dataset) {
      if(dataset.chartKey != 'costs2') {
        let monthName = this.$t('gantt_months_list').split('_') // meses separados por '_'
        let currentMonth = parseInt(moment().format('M')) - 1 // mes actual -1 para encajar con lista meses gantt
        monthName = monthName[currentMonth].toLowerCase() // mes lowercase, la clave de busqueda mes actual en el dataset
        this.projectNumbers[dataset.chartKey].estimated = dataset[0]['data'][monthName] // en 0 estan los datos estimados
        this.projectNumbers[dataset.chartKey].real = dataset[1]['data'][monthName] // en 1 estan los datos reales
        this.projectNumbers[dataset.chartKey].suffix = dataset.suffix
      } else {
        let currentMonth = moment().format('YYYY.MM') //- 1 // mes actual -1 para encajar con lista meses gantt
        this.projectNumbers['costs'].estimated = dataset[0]['data'][currentMonth] // en 0 estan los datos estimados
        this.projectNumbers['costs'].real = dataset[1]['data'][currentMonth] // en 1 estan los datos reales
        this.projectNumbers['costs'].suffix = dataset.suffix
      }
    },

    onRowClicked(isSubactivity, activityId, subactivityId, access = true) {
      if (access) {
        if (!isSubactivity) {
          this.$router.push({name: 'activity.home', params: {activity_id: activityId}})
        } else {
          this.$router.push({
            name: 'subactivity.home',
            params: {activity_id: activityId, subactivity_id: subactivityId}
          })
        }
      }
    },

    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },

    formatCurrency(value, suffix) {
      let mult = 1;
      switch (suffix) {
        case 'K':
          mult = 1000;
          break;
        case 'M':
          mult = 1000000;
          break;
        case 'B':
          mult = 1000000000;
          break;
        case 'T':
          mult = 1000000000000;
          break;
      }

      if( typeof value === 'undefined' || isNaN(value) )
        return '---'

      return this.toCurrency(parseFloat(value) * mult);
    },

    _HandleChangeTab(value) {
      // value contiene el index del tab pulsado siendo
      // 0= el primero tab que corresponde a al menu comments panel comentarios
      // 1= el segundo tab que corresponde al menu dasboard panel derecho

      this.showPanelRight = value === 1
      this.showPanelLeft = value === 0
    }

  },

};
</script>

<style scoped>
.containt-tabs-menu::v-deep .b-tabs section {
  display: none;
}
.column-scroll {
  /* width: 50%; */
  height: calc(100vh - 150px);
  overflow-x: hidden;
  overflow-y: auto;
}
/* .column-scroll:not(:last-child) {
  margin-right: 0.5rem;
}
.column-scroll:last-child {
  margin-left: 0.5rem;
} */

div.w-3\/5 {
  width: 60% !important;
}

div.w-2\/5 {
  width: 40% !important;
}
</style>
