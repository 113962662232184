<template>
  <b-modal :active.sync="isModalActive"  scroll="keep">
    <div class="flex flex-col border border-blue-light rounded shadow bg-white p-4">

      <div class="flex flex-col w-full">
        <div class="uppercase font-semibold text-blue">{{ $t('certify_executed_quantities') }}</div>
        <div class="text-blue"></div>
      </div>

      <import-entities
          :can-overwrite="true"
          :project-id="project ? project.id : 0"
          entity-type="assignment"
          :entity-id="entityPlanningCode"
          :with-history="true"
          action-type="certificateQuantity"
          :assignment-type="assignmentType"
          @success="hide()"
          :perPage="5"
        >
      </import-entities>

    </div>
  </b-modal>
</template>

<script>
  import ImportEntities from "·/components/import-entities";
  import {mapGetters} from "vuex";

  export default {
    name: "assignment-certified-execution-modal",

    components: {
      ImportEntities
    },

    props: {
      entityId: {type: Number, required: true},
      entityType: {type: String, required: true},
      entityPlanningCode: { type: String, required: false, default: '' },
      assignmentType: { type: String, default: 'certifiers' }, // para cargar certificadoras se requiere que por defecto sea seleccionado
    },

    data: () => ({
      isModalActive: false,
    }),

    computed: {
      ...mapGetters({
        project: "app/project"
      })
    },

    methods: {

      /**
       * Abre este modal
       */
      show() {
        this.isModalActive = true;
      },

      /**
       * Oculta este formulario
       */
      hide() {
        this.$emit("reload");
        this.isModalActive = false;
      }
    }
  };
</script>
<style scoped>
  /* Oculta los botones para aumentar los inputs numéricos */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
</style>
