
// state
export const state = {
    // issuesCorporateZone: puede ser 'standard' o 'advanced'
    issuesCorporateZone: 'standard',
  }


// mutations
export const mutations = {
    saveIssuesCorporateZone (state, flag) {
      state.issuesCorporateZone = flag
    },
}

// actions
export const actions = {
    setIssuesCorporateZone ({ commit }, payload) {
      commit('saveIssuesCorporateZone', payload)
    },
}

// getters
export const getters = {
    issuesCorporateZone: state => state.issuesCorporateZone,
}
